  .BlogSectionPort{
    padding: 40px 0;
    background-color: #f0f4f8;
  }
  .text{
    display: flex;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    text-align: left;
    color: #90A0B7;
    text-transform: none;
    padding: 10px 20px 10px 20px;
    margin: 0px !important;
  }
  .blogCardH3{
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    text-align: left;
    color: #90A0B7;
    text-transform: none;
    padding: 10px 20px 10px 20px;
    margin: 0px !important;
  }
  .blogCardP{
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    color: #666;
  }
  .BlogHead {
    text-align: center;
    color: black;
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 20px;
}
.blogSecondHead{
  text-align: center;
  color: #192A3E;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  margin-bottom: 20px;
}
.cardBlog:hover{
  cursor: pointer;
}
.imgDivBlogs{
  height: 175px;
}

.imgDivBlogs img{
  height: 100% !important;
  width: 100%;
  border-top-right-radius:10px;
  border-top-left-radius:10px;
}
.spanReadMore{
text-decoration: underline;
font-size: 12px;
margin-left: 5px;
white-space: nowrap;
font-size: 14px!important;
color: #e89246;
}
.postDetailsGrid {
  display: flex;
  gap: 40px;
  margin: 25px; 
  margin-top: 40px;
  margin-bottom: 70px;
  padding: 35px;
}
.ts-row {
  min-height: 1px;
  margin-right: -15px;
  margin-left: -15px;
  box-sizing: border-box;
}
.main .main-content {
  width: 100%;
}
.column, .ts-row [class^=col-], .ts-row [class*=" col-"] {
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  min-height: 1px;
  box-sizing: border-box;
  font-size: 17px;
}
.main-content img{
    will-change: opacity;
    transition: opacity .3s ease-in;
    border-radius: 8px;
}
.imgPara{
  display: flex;
  justify-content: center;
}

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,900,400italic);

.recent_posts h1 {
	font: 900 1.77rem/3rem 'Source Sans Pro';
}
.recent_posts {
	background: #fff;
	margin: 0 auto;
	padding: 1rem;
	position: relative;
	top: 1.5rem;
	width: 100%;
}

.box{
  /* box-shadow: 4px 4px 20px rgba(1, 1, 1, .2); */
  cursor: pointer;
  position: relative;
  background: none;
	padding: 15px 0px;
  border-bottom: 1px solid black !important;
  display: flex;
}
/* .box:hover{
  transform: translateY(-10px);
} */
.imgDivPost{
  flex: 30%;
  padding: 10px;
  padding-top: 0px;
}
.imgDivPost img{
  width: 100%;
  height: 100%;
}
.postDetailDiv1{
  flex: 70%;
  padding: 10px;
}
.NamePost{
   font-weight: 600;
   font-size: 1rem;

}
.subTopicPost{
  font-size: 0.8rem;
  font-weight: 500;
}
.secondContent{
  position: sticky;
}
.colBlogsProject{
  margin-bottom: 25px;
}



/* New Blog Card For Blog Page */
.BlogCard{
  /* background-color: #fff; */
  /* box-shadow: 0 2px 8px rgba(0,0,0,.16); */
  /* border-radius: 8px; */
}
.imgDivBlogCard{
  height: 210px;  
  border: 10px solid white; 
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
}
.imgDivBlogCard img{
  height: 100%;
  width: -webkit-fill-available !important;

  /* border-top-right-radius: 8px; */
  /* border-top-left-radius: 8px; */
}
.textBlogCard{
  /* padding: 0.75rem; */
  padding-top: 1rem;
  justify-content: left;
}
.textBlogCard1 h4{
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #222;
}
.textBlogCard1 p{
 color: #7a6e77;
 margin-top: 0.75rem;
 font-size: 14px;
}

.BlogCard:hover{
  /* box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 15px 10px rgba(0,0,0,.1); */
    /* transform: translateY(-2px); */
    cursor: pointer;
}

.secondContent{
  position: sticky;
  top: 5rem; /* Adjust as needed */
  z-index: 100;
}

.main-content p{
  font-size: medium;
}

.sliderBlogCard{
  width: 91% !important;
}





  