body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999 !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.ReactModal__Content {
  padding: 0px !important;
  background-color: transparent !important;
  border: none !important;
}

/* @media only screen and (max-width: 430px) and (min-width: 200px) {
  .ReactModal__Content {
    inset: 50% 15% 0% 50% !important;  
  }
} */
/* ================================budget Section css======================*/
.owl-pagination-custom {
  justify-content: space-between;
  margin-top: -35px;
  padding: 0 25px;
  display: none;
}
/* =========================icon  box css================== */
.buttonBox {
  background: lightgray;
  color: black;
  font-weight: 600;
  text-transform: uppercase;
  border: navajowhite;
  font-size: 12px;
  width: 142px;
  text-align: center;
  /* padding: auto;    */
  border-radius: 50px;
  margin-bottom: 20px;
  display: none;
}
.buttonBox img {
  height: 5vh;
  width: 5vh;
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Heading {
  text-align: Center;
  font-size: 50px;
  color: white;
}
.galleryItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.galleryItem:hover{
   cursor: pointer;       
}
.galleryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding-bottom: 29px;
}
.galleryWrapper {
  padding: 3% 10%;
}

.filterItem ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 20px;
  margin-bottom: 30px;
}

.filterItem ul button {
  background: #fff;
  color: black;
  font-weight: 600;
  padding: 8px 46px;
  text-transform: uppercase;
  border: navajowhite;
  font-size: 12px;

  text-align: center;
  border-radius: 50px;
}
.filterItem ul button img {
  height: 5vh;
  width: 5vh;
  color: white;
}

.filterItem ul button:hover {
  background: #e89246;
  cursor: pointer;
  font-weight: 700;
  color: black;
}
.galleryItem {
  overflow: hidden;
  display: inline-block;
  padding-left: 10px;
}
.galleryItem img {
  -webkit-animation: fadeInRight 0.5s ease-in-out;
  animation: fadeInRight 0.5s ease-in-out;
  width: 300px;
  border-radius: 10px;
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media only screen and (max-width: 800px) and (min-width: 600px) {
  .filterItem ul {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .filterItem ul {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1155px) {
  /* .react-multi-carousel-list {
  display: none;         
}  */
  .galleryContainer {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    padding-bottom: 29px;
  }
  .filterItem ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 1150px) and (min-width: 800px) {
  .galleryContainer {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    padding-bottom: 29px;
  }
  .filterItem ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 766px) and (min-width: 250px) {
  .galleryContainer {
    display: none;
  }
  .filterItem ul {
    display: none;
  }
  .owl-pagination-custom {
    justify-content: space-between;
    margin-top: -35px;
    padding: 0 25px;
    display: block;
    margin-top: 8px;
  }
  .col-lg-9 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }
  .buttonBox {
    background: #3b3b3b;
    color: white;
    font-weight: 600;
    /* padding: 8px 46px; */
    text-transform: uppercase;
    border: navajowhite;
    font-size: 12px;
    width: 142px;
    /* text-align: center;   */
    border-radius: 50px;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
*{
  font-family: 'Poppins', sans-serif;
}
.detailDiv{
    padding: 1rem;
    height: 65vh !important;
    overflow: auto;
    scrollbar-width: none;  /* Firefox */
}
.detailDiv::-webkit-scrollbar {
  display: none;
}

.mainDivService {  
  display: grid;
  /* padding: 1rem; */
  grid-template-columns: 65% 1fr;
  gap: 0rem !important;
  /* border: 2px solid black; */
  align-items: center;
  max-width: 80%;
  margin: 5% auto;
  max-height: 85%;
  overflow: hidden;
  font: 500 100%/1.5 system-ui;
  background:#fff;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .mainDivService{
    grid-template-columns:  1fr;
    overflow: scroll;
    max-height: max-content;
  }
  .detailDiv{
    height: 100% !important;
  }
}
.HeadingName{
  font-size: 22px;
  font-weight:700;
  color:#414042;
  text-align: justify !important;
}
.description{
  font-size:12px;
  margin-top: 8px !important;
}
.bookButton{
  border: 1px solid #e89246;
  background-color: #e89246;
  border-radius: 2px;
  width: 95%;
  padding: 5px;
  color: white;
}
.innerDivModal{
   padding: 4rem; 
   height: 90vh;
}
.imgServiceModal {
  width: 100%;
  height: 450px;
  box-shadow: 2px 2px 42px 2px #ddd;
}
.social-links{
  display: flex;
  align-items: center;
}
.social-links .iconsModal{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #e89246;
  /* border: 1px solid #000; */
  margin: 0 8px;
  border-radius: 50%;
  border: 1px solid #e89246;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
  background-color: #fff;
}
.social-links .iconsModal:hover{
  background: #e89246;
  border-color: #e89246;
  border: 1px solid #e89246;
  color: #fff;
}
.ShareDesign{
  font-size: 16px;
}
.FacebookIcon{
  margin-left: 0px !important;
}
@media (max-width: 1000px) { 
  .main {
    display: block;
  }
  .description {
    position: relative;
    background-color: #ffffff85
  }
  .imgServiceModal {
    width: 100%;
    background-position:center;
    object-fit:cover;
  }
  h1{
    font-size:35px;
    padding-top:0px;
    padding-bottom:0px;

  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.maxHeight450{
  max-height: 480px !important; 
  width: 100% !important;
  overflow: hidden;
}
.maxHeight450 div{
  height: 65vh !important;
}
.maxHeight450 img{
  width: -webkit-fill-available !important;
  height: -webkit-fill-available;

}

.FramerMotionCarouselModal span {
  display: none !important;
}
.FramerMotionCarouselModal {
  height: 65vh; 
  overflow: hidden; 
  background-color: rgb(45, 45, 45); 
}

.CarouselPropModal .react-multi-carousel-item{
  display: flex !important;
  justify-content: center !important;
}
.custom-carousel-modal {
  height: 100%; 
}

.carousel-image {
  height: 100%;
  width: 100%;
  object-fit: cover; 
  cursor: pointer;
}

.img-thumbnail {
  margin-bottom: 25px; 
  height: 11vh;
  width: 100%;
}
.img-thumbnail:hover{
  cursor: pointer;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.bookButton:hover{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); 
}
.react-multiple-carousel__arrow {
  z-index: 0;  
}

.servicesResponsiveBtn{
  font-size: 17px !important;
}
.titleBox{
  font-size: 14px !important;
}

@media (max-width: 768px) {  
  .ReactModal__Content { 
    inset: 45% auto auto 50% !important;   
  }
}



.cardProp{
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
  border-radius: 8px;
}
.imgDivPropCard{
  height: 210px;   
}
.imgDivPropCard img{
  height: 100%;
  width: -webkit-fill-available !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.textCardProp{
  padding: 0.75rem;
  justify-content: center;
}
.textCardProp1 h4{
  line-height: 24px;
  font-size: 20px;
  color: #41303e;
 text-align: center;
 margin-top: 0.50rem;
}
.textCardProp1 p{
  line-height: 24px;
  font-size: 16px;
  color: #7a6e77;
 text-align: center;
 margin-top: 0.50rem;
}
.textCardPropIcon{
  display: flex;
  justify-content: center;
}
.textCardPropIcon span{
  width: 28px;
  text-align: center;
  border-radius: 25px;
}
.textCardPropIcon i{
 font-size: 24px !important;
}
.cardProp:hover{
  box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 15px 10px rgba(0,0,0,.1);
    transform: translateY(-2px);
    cursor: pointer;
}
.cardProp:hover .textCardIconSpan{
  background-color: #e89246;
  color: #fff !important;
}
.ServiceHead{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.ServiceTopHeading{
  text-align: center;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
}
.serviceDivHover{
  width: 91% !important;
}
.ServiceSectionPort{
  background-color: rgba(44,37,45,.04);
  padding-bottom: 2rem;
}
.serviceCrauselHome .react-multi-carousel-dot-list{
  bottom: -2px !important;   
} 

@media (max-width: 576px) { 
    .serviceCrauselHome li{  
    /* margin-right: -30px;    */
  } 
  .FramerMotionCarouselModal {
    height: 55vh !important; 
    overflow: hidden; 
    background-color: rgb(45, 45, 45); 
  }
  .ServiceSectionPort {
    background-color: #fff !important;
  }
  .innerDivModal{
    padding: 1rem !important;
    padding-top: 3rem !important;
  }
  .mainDivService{
    width: 100% !important;
    max-width: 100% !important;
  }
  .mainDivProp{
    width: 100% !important;
    max-width: 100% !important;
  }
  .serviceCrauselHome .react-multi-carousel-dot-list{
    /* display: none; */
  }
  .closeBtnServiceModal{  
    top: 5%;
    right: 0% !important;
  }
.CarouselPropModal .react-multiple-carousel__arrow {
 display: block !important;          
}
.serviceCrauselHome .react-multiple-carousel__arrow{
  display: none !important;
} 

}
.PhotoView-Portal{
  z-index: 99999999 !important;
}

/* @media (max-width: 464px) { 
  .serviceCrauselHome li{  
  width: 260px !important;   
} 
} */
  


