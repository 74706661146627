/* body{
    padding-top: 0px !important;
} */
.navbar-brand img {
    width: 136px;
  }
  .container-fluid{
    align-items: center;
    justify-content: center !important;
  }
  .navbar-nav {
    align-items: center;
  }
  .navbar .navbar-nav .nav-link {
    color: #fff;
    font-size: 1.1em;
    padding: 0.5em 1em;
  }
  .navbar-brand{

  }
  /* @media screen and (min-width: 768px) {
    .navbar-brand img {
      width: 100px;
    }
  } */

  /*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.footer {
    font-size: 14px;
    background-color: #1f1f24;
    padding: 15px 0;
    color: rgba(255, 255, 255, 0.7);
  }
  .addressDiv {
    margin-top: -20px;
  }
  .appExchange {
    margin-top: -10px;
  }
  .footer .icon {
    margin-right: 15px;
    font-size: 24px;
    line-height: 0;
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
    color: #fff;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: #fff;
  }
  
  .footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: #fff;
    border-color: #fff;
  }
  
  .footer .copyright {
    text-align: center;
    padding-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .footer .credits {
    padding-top: 4px;
    text-align: center;
    font-size: 13px;
  }
  
  .footer .credits a {
    color: #fff;
  }
  .PfooterNew{
    color: #fff !important;
  }
    @media screen and (min-width: 1200px) {
      .addressDiv {
        margin-left: 18vh;
      }
  }


  /*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.about .count-box {
  padding: 60px 0;
  width: 100%;
}

.about .count-box i {
  display: block;
  font-size: 48px;
  color: #a1bdd1;
  float: left;
  line-height: 0;
}

.about .count-box span {
  font-size: 28px;
  line-height: 25px;
  /* display: block; */
  font-weight: 700;
  color: #365870;
  margin-left: 11px;
}

.about .count-box p {
  padding: 5px 0 0 0;
  margin: 0 0 0 60px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #2e4b5e;
}

.about .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #2e4b5e;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.about .count-box a:hover {
  color: #477392;
}

.about .content {
  font-size: 15px;
}

.about .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #263d4d;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.about .content ul i {
  font-size: 24px;
  color: #ff4a17;
  position: absolute;
  left: 0;
  top: -2px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#ff4a17 50%, rgba(255, 74, 23, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 74, 23, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #ff4a17;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
.cardBlog{
  border-radius: 10px;
  border: 2px solid #D8D8D8;
  height: max-content;
  width: 90% !important;
}
.LandingCmpHead{
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}
.LandingCmpHeadDiv1{
  flex: 20%;
}
.LandingCmpHeadDiv2{
  flex: 80%;
}
.LandingCmpHeadDiv1 div{
  height: 6px;
  background-color: #e89246;
  margin-top: 36px;
}
.imgDivLanding{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.cardLanding{
  padding: 20px 0px;
  width: 100%;
  height: max-content;
  border-radius: 8px;
  border: 1px solid #6DB4C433;
  box-shadow: 0px 6px 40px 0px rgba(109, 180.0000000000001, 196.00000000000003, 0.12);
}
.imgDivH2{
  text-align: center;
}
.imgDivH2 h2{
  color: #192A3E;
    font-family: "Montserrat", Sans-serif;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 24.38px;
}
.MainHomeDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.mainHomeSection{
  height: auto;
  /* background-image: url("https://content.officebanao.com/get-in-touch/hero-image.jpg"); */
  background-size: cover;
  background-position-y:bottom;
}
.MainHomeDivInside1{
  flex: 50%;
  margin-top: 50px 0px;
}
.divBlockHome{
  border-left: 0px;
  padding: 50px;
  background: linear-gradient(92.24deg, #0E414D 61.97%, rgba(14, 17, 35, 0) 115.47%);
  filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.1));
  border-radius: 0px 8px 8px 0px;
  backdrop-filter: blur(5px);
}
.divBlockHome h1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 10px;
  text-align: left;

}
.divBlockHome p{
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0;
    text-align: left;
}
.MainHomeDivInside2{
  flex: 50%;
}
.login {
  margin: 20px auto;
  padding: 24px;
  max-width: 350px;
  width: 340px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 1px #666;
  margin-top: 75px;
  backdrop-filter: blur(22px);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgb(255 255 255) 100%) !important;
}
  .login input {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0.75rem;
    font-size: 16px;
    font-family: Raleway, sans-serif;
    background: transparent;
    border: 1px solid #323C47;
    border-radius: 10px;
    margin-bottom: 20px;
    color: #323C47 !important;
  }
  .login select {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0.75rem;
    font-size: 16px;
    font-family: Raleway, sans-serif;
    background: transparent;
    border: 1px solid #323C47;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .login input::placeholder{
    color: #323C47;
  }

.login input:hover {
  /* border-color: #F44336; */
  outline: none;
  transition: all .2s ease-in-out;
} 

.buttonFormMainHome{
  border: none;
  background: #e89246 ;
  color: white;
  font-weight: bold;  
  transition: 0.2s;
  width: 100%;
  padding: 10px 0px;
  border-radius: 10px;
}
.buttonFormMainHome:hover {
  background: #e89246 ;  
}

  .login h2 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #323C47;
    margin-bottom: 30px;
  }

.login p {
  margin-bottom: 40px;
}

.links {
  display: table;
  width: 100%;  
  box-sizing: border-box;
  border-top: 1px solid #c0c0c0;
  margin-bottom: 10px;
}

.links a {
  display: table-cell;
  padding-top: 10px;
}

.links a:first-child {
  text-align: left;
}

.links a:last-child {
  text-align: right;
}

  .login h2,
  .login p,
  .login a {
    text-align: center;    
  }

.login a {
  text-decoration: none;  
  font-size: .8em;
}

.login a:visited {
  color: inherit;
}

.login a:hover {
  text-decoration: underline;
}
.columnLanding{
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .MainHomeDiv{
    display: grid;
  }
  .buttonFormMainHome{
    font-size: 16px !important;
  }
}
@media screen and (max-width: 350px) {
  .login{
    width: 270px;
    max-width: 270px;
  }
}
@media screen and (max-width: 992px) {
  .about-boxesLanding{
    padding-left: 10vh !important;
    padding-right: 10vh !important;
  }
}
@media screen and (max-width: 575px) {
  .cardBlog{
    width: 100% !important;
    margin-left: 50px;
    margin-right: 50px;
  }
}


/* For Floor Plan Page */
.cardPropFloor{
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
}
.imgDivPropCardFloor{
  height: 180px;   
}
.imgDivPropCardFloor img{
  height: 100%;
  width: -webkit-fill-available !important;  
}
.textCardPropFloor{
  padding: 0.75rem;
  justify-content: center;
}
.textCardProp1Floor h4{
  line-height: 24px;
  font-size: 18px; 
  color: #41303e;
 text-align: center;
 margin-top: 0.50rem;
}

.FloorSection{
  background-color: #e9e9e947;
  padding-bottom: 2rem;
}

.cardPropFloorComm{
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
}
.imgDivPropCardFloorComm{
  height: 250px;   
}
.imgDivPropCardFloorComm img{
  height: 100%;
  width: -webkit-fill-available !important;  
}
.textCardPropFloorComm{
  padding: 0.75rem;
  justify-content: center;
}
.textCardProp1FloorComm h4{
  line-height: 24px;
  font-size: 18px;
  color: #41303e;
 text-align: center;
 margin-top: 0.50rem;
}

.closeBtnFloor{
  float: right;
  color: black;
  font-weight: 700;
  font-size: 18px;
  background: transparent;
  border: none;
  margin-top: -15px;
}
.floorModal{
  background: white !important;
}

.TopHeadingFloor{
  text-align: center;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
}
   @media screen and (max-width: 500px) {
    .TopHeadingFloor {
      font-size: 28px !important;
    }
    .container{
      padding-left: 30px !important;
      padding-right: 30px !important;     
    }
  } 


  @media only screen and (max-width: 3500px) and (min-width: 992px){  
    .footerMiddleCol{
      justify-content: center !important;
    }
    .footerLastCol{
      padding-left: 8.5rem !important;     
    }
 }


  