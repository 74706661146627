
.cardPropFloor{
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,.16);
    border-radius: 4px;
  }
  .textCardPropFloorIcon{
    display: flex;
    justify-content: center;
  }
  .textCardPropFloorIcon span{
    width: 28px;
    text-align: center;
    border-radius: 25px;
  }
  .textCardPropFloorIcon i{
   font-size: 24px !important;
  }
  .cardPropFloor:hover{
    box-shadow: 0 4px 4px rgba(0,0,0,.05), 0 0 4px 4px rgba(0,0,0,.1);  
      /* transform: translateY(-2px); */
      cursor: pointer;
  }
  .cardPropFloor:hover .textCardIconFloorSpan{
    background-color: #e89246;
    color: #fff !important;
  }
  .FloorHead{
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 130px;
  }
  .FloorTopHeading{
    text-align: left;
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
  }
  .serviceDivHover{
    width: 91% !important;
  }
  .FloorSectionPort{
    background-color: rgba(44,37,45,.04);
    padding-bottom: 2rem;
  }
  .floorCrauselHome .react-multi-carousel-dot-list{
    bottom: -2px !important;   
  } 
  
  @media (max-width: 576px) { 
      .floorCrauselHome li{  
      /* margin-right: -30px;    */
    } 
    .FramerMotionCarouselModal {
      height: 55vh !important; 
      overflow: hidden; 
      background-color: rgb(45, 45, 45); 
    }
    .ServiceSectionPort {
      background-color: #fff !important;
    }
    .innerDivModal{
      padding: 1rem !important;
      padding-top: 3rem !important;
    }
    .mainDivService{
      width: 100% !important;
      max-width: 100% !important;
    }
    .mainDivProp{
      width: 100% !important;
      max-width: 100% !important;
    }
    .floorCrauselHome .react-multi-carousel-dot-list{
      /* display: none; */
    }
    .closeBtnServiceModal{  
      top: 5%;
      right: 0% !important;
    }
  .CarouselPropModal .react-multiple-carousel__arrow {
   display: block !important;          
  }
  .floorCrauselHome .react-multiple-carousel__arrow{
    display: none !important;
  } 
  
  }

  @media (max-width: 768px){
    .FloorHead{
        padding-left: 45px;
    }
  }
  @media (max-width: 576px){
    .FloorHead{
        padding-left: 15px;
    }
    .FloorTopHeading{
        font-size: 30px;
    }
  }





  /* For Floor Page New Full Page */

  
.ReactModal__Overlay {
    position: fixed;  
    z-index: 999 !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  .ReactModal__Content {
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;
  }

  .picZoomer{
	position: relative;
    /*margin-left: 40px;
    padding: 15px;*/
}
.picZoomer-pic-wp{
	position: relative;
	overflow: hidden;
    text-align: center;
}
.picZoomer-pic-wp:hover .picZoomer-cursor{
	display: block;
}
.picZoomer-zoom-pic{
	position: absolute;
	top: 0;
	left: 0;
}
.picZoomer-pic{
	/*width: 100%;
	height: 100%;*/
}
.picZoomer-zoom-wp{
	display: none;
	position: absolute;
	z-index: 999;
	overflow: hidden;
    border:1px solid #eee;
    height: 460px;
    margin-top: -19px;
}
.picZoomer-cursor{
	display: none;
	cursor: crosshair;
	width: 100px;
	height: 100px;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	border: 1px solid #eee;
	background-color: rgba(0,0,0,.1);
}
.picZoomCursor-ico{
	width: 23px;
	height: 23px;
	position: absolute; 
	top: 40px;
	left: 40px;
	background: url("https://static.wixstatic.com/media/e2392d_1a498632a188458485b98e4ccb85401e~mv2.jpg/v1/crop/x_0,y_31,w_307,h_365/fill/w_364,h_433,al_c,lg_1,q_80,enc_auto/Asset%201-100.jpg") left top no-repeat;
}

.my_img {
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
}
.piclist li{
    display: inline-block;
    width: 90px;
    height: 114px;
    border: 1px solid #eee;
}
.piclist li img{
    width: 97%;
    height: auto;
}

/* custom style */
.picZoomer-pic-wp,
.picZoomer-zoom-wp{
    border: 1px solid #eee;
}



.section-bg {
    background-color: #fff1e0;
}
section {
    /* padding: 60px 0; */
}
.row-sm .col-md-6 {
    padding-left: 5px;
    padding-right: 5px;
}

/*===pic-Zoom===*/
._boxzoom .zoom-thumb {
    width: 90px;
    display: inline-block;
    vertical-align: top;
    margin-top: 0px;
}
._boxzoom .zoom-thumb ul.piclist {
    padding-left: 0px;
    top: 0px;
}
._boxzoom ._product-images {
    width: 80%;
    display: inline-block;
}
._boxzoom ._product-images .picZoomer {
    width: 100%;
}
._boxzoom ._product-images .picZoomer .picZoomer-pic-wp img {
    left: 0px;
}
._boxzoom ._product-images .picZoomer img.my_img {
    width: 100%;
}
.piclist li img {
    height:100px;
    object-fit:cover;
}

/*======products-details=====*/
._product-detail-content {
    background: #fff;
    padding: 15px;
    border: 1px solid lightgray;
}
._product-detail-content p._p-name {
    color: black;
    font-size: 20px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 12px;
}
.p-list span {
    margin-right: 15px;
}
.p-list span.price {
    font-size: 25px;
    color: #318234;
}
._p-qty > span {
    color: black;
    margin-right: 15px;
    font-weight: 500;
}
._p-qty .value-button {
    display: inline-flex;
    border: 0px solid #ddd;
    margin: 0px;
    width: 30px;
    height: 35px;
    justify-content: center;
    align-items: center;
    background: #fd7f34;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
}

._p-qty .value-button {
    border: 0px solid #fe0000;
    height: 35px;
    font-size: 20px;
    font-weight: bold;
}
._p-qty input#number {
    text-align: center;
    border: none;
    border-top: 1px solid #fe0000;
    border-bottom: 1px solid #fe0000;
    margin: 0px;
    width: 50px;
    height: 35px;
    font-size: 14px;
    box-sizing: border-box;
}
._p-add-cart {
    margin-left: 0px;
    margin-bottom: 15px;
}
.p-list {
    margin-bottom: 10px;
}
._p-features > span {
    display: block;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}
._p-add-cart .buy-btn {
    background-color: #fd7f34;
    color: #fff;
}
._p-add-cart .btn {
    text-transform: capitalize;
    padding: 6px 20px;
    /* width: 200px; */
    border-radius: 52px;
}
._p-add-cart .btn {
    margin: 0px 8px;
}

/*=========Recent-post==========*/
.title_bx h3.title {
    font-size: 22px;
    text-transform: capitalize;
    position: relative;
    color: #fd7f34;
    font-weight: 700;
    line-height: 1.2em;
}
.title_bx h3.title:before {
    content: "";
    height: 2px;
    width: 20%;
    position: absolute;
    left: 0px;
    z-index: 1;
    top: 40px;
    background-color: #fd7f34;
}
.title_bx h3.title:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 40px;
    background-color: #ffc107;
}
.common_wd .owl-nav .owl-prev, .common_wd .owl-nav .owl-next {
    background-color: #fd7f34 !important;
    display: block;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 0px !important;
}
.owl-nav .owl-next {
    right:-10px;
}
.owl-nav .owl-prev, .owl-nav .owl-next {
    top:50%;
    position: absolute;
}
.common_wd .owl-nav .owl-prev i, .common_wd .owl-nav .owl-next i {
    color: #fff;
    font-size: 14px !important;
    position: relative;
    top: -1px;
}
.common_wd .owl-nav {
    position: absolute;
    top: -21%;
    right: 4px;
    width: 65px;
}
.owl-nav .owl-prev i, .owl-nav .owl-next i {
    left: 0px;
}
._p-qty .decrease_ {
    position: relative;
    right: -5px;
    top: 3px;
}

._p-qty .increase_ {
    position: relative;
    top: 3px;
    left: -5px;
}
/*========box========*/
.sq_box {
    padding-bottom: 5px;
    border-bottom: solid 2px #fd7f34;
    background-color: #fff;
    text-align: center;
    padding: 15px 10px;
    margin-bottom: 20px;
    border-radius: 4px;
}
.item .sq_box span.wishlist {
    right: 5px !important;
}
.sq_box span.wishlist {
    position: absolute;
    top: 10px;
    right: 20px;
}
.sq_box span {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 10px;
}
.sq_box span.wishlist i {
    color: #adb5bd;
    font-size: 20px;
}
.sq_box h4 {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    color: #343a40;
    margin-top: 10px;
    margin-bottom: 10px !important;
}
.sq_box .price-box {
    margin-bottom: 15px !important;
}
.sq_box .btn {
    border-radius: 50px;
    padding: 5px 13px;
    font-size: 15px;
    color: #fff;
    background-color: #fd7f34;
    font-weight: 600;
}
.sq_box .price-box span.price {
    text-decoration: line-through;
    color: #6c757d;
}
.sq_box span {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 10px;
}
.sq_box .price-box span.offer-price {
    color:#28a745;
}
.sq_box img {
    object-fit: cover;
    height: 150px !important;
    margin-top: 20px;
}
.sq_box span.wishlist i:hover {
    color: #fd7f34;
}







.padding-0-2vh{
    padding: 0 2vh !important;  
}
.header-h2
{
    font-size: 20px;
}
.header-h1 {
    font-size: 42px;
    text-align: left;
}
.fs-16, .header-h3 {
    font-size: 16px;
}
.sx-tilte{
    position: relative;
}
.site-button2 {
    background-color: #e89246;
    /* color: #fff!important; */
    font-size: 17px;
    padding: 10px;
    color: white !important;
    border-radius: 5px;
}
.site-button2:hover{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); 
}
.site-button-red-bg, .site-button-white-bg, .site-button2 {
    border: 0 solid transparent;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.42857;
    margin-left: -1px;
    outline: 0;
    position: relative;
    text-decoration: none!important;
}
.fw-400 {
    font-weight: 500!important;
}
.headerDivFloor{
    width: fit-content !important;
    margin-bottom: 3rem;
    background-color: #fff8f7;
}
.card-img-top:hover{
    cursor: pointer;
}
.iconCard{
    background: #e89246; 
    border-radius: 50px;
    color: white;
    padding: 5px;
}
.imgCrausel{
    width: 60% !important;
    height: 100%;
    cursor: pointer;
}
.fs-1rem {
    font-size: 1.5rem;
}
.bl-0 {
    border-left: initial !important;
}
.cell-2 {
    border-bottom: 1px solid #c9cbd8;
    border-left: 1px solid #c9cbd8;
    font-size: 16px;
    font-weight: 500;
}
.cell {
    border: 1px solid #c9cbd8;
    font-size: 17px;
    padding: 10px 0;
    text-align: center;
}
.cell, .cell-2 {
    align-items: center;
    justify-content: center; 
    display: flex;
}
.cell-2{
    display: flex;
    text-align: center;
}
.detailIcon {
    margin-top: -15px;
    padding: 0 10px;
    height: 30px;
}
.bb-0 {
    border-bottom: initial;
}
.grid {
    grid-template-columns: repeat(3,150px);
    margin-bottom: 20px;
    grid-gap: 0;
    display: grid;
}
.grid-2 { 
    grid-template-columns: repeat(2,200px);
    grid-gap: 0;
    display: grid;
}
.detail-btn {
    height: 50px;
    width: 35%;  
    font-size: 20px;
    font-weight: 600;
    border-radius: 0px !important;
    text-align: center;
    letter-spacing: 4px !important;
}
.detail-btn:hover{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);  
}
.site-cart-btn {
    letter-spacing: 0;
    padding: 10px 15px;
}
.active>.site-button, .site-button, .site-button:active, .site-button:focus, .site-button:hover, input[type=Label]:hover {
    background-color: #e89246;
    color: Black;
}
.site-button, .site-button-secondry {
    border: 0 solid transparent;
    cursor: pointer;
    display: inline-block; 
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 8px;
    line-height: 1.42857;
    margin-left: -1px;
    border-radius: 4px;
    /* padding: 15px; */
    position: relative;
}
.site-button, .site-button-secondry {
    outline: 0;
    text-decoration: none!important;
} 
.carousel .thumb{
    text-align: center !important;
}
.wt-product-text {
    margin-bottom: 30px;
}
.border-style {
    border-left: 4px solid #c91006;
    padding-left: 20px;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    background: #ffdc25 !important;
    opacity: 1 !important;
    color: red;
    z-index: 1 !important;
}
.carousel .control-disabled.control-arrow {
     opacity: 1 !important;
     color: red;
}
.control-prev.control-arrow::before {
    color: red; /* Change the color to red */
  }
 
  .floorIntro {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .FloorPlanSection{
    border-top: 1px solid black;
  }

@media only screen and (max-width: 1370px) and (min-width: 1200px){
   .FloorPlanSection{
    /* margin-top: 0rem !important; */
   } 
}
@media only screen and (max-width: 575px){
.FloorPlan{
    font-size: 26px!important;  
}
}
@media only screen and (max-width: 767px) and (min-width: 500px){  
  .floorIntro {
    padding-left: 1rem !important;
    padding-right: 1rem !important;  
    margin-bottom: 1rem !important;
  }
  .cardFloor {
    padding-left: 0rem !important;
    padding-right: 0rem !important;  
  }
  .site-button2{
    font-size: 16px !important;
  }
  
}
@media only screen and (max-width: 575px) and (min-width: 250px){  
     .projectOpenSection{
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
     }

   
   
      .CardInResponsive{  
        display: flex;
        justify-content: center;
      }
    .cardFloor {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  }
  @media only screen and (max-width: 575px) and (min-width: 250px){  
      .CardInResponsive{
        display: flex;
        justify-content: center;
      }
    .cardFloor {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  }
  @media only screen and (max-width: 320px) and (min-width: 250px){  
    .cardFloor {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
  @media only screen and (max-width: 450px) and (min-width: 250px){  
  
    .cell-2{
        font-size: 14px !important;
    }
  }
/* @media only screen and (max-width: 800px) and (min-width: 500px){
 .detail-btn{
    font-size:14px !important; 
  }
} */
@media only screen and (max-width: 768px) and (min-width: 576px){
    .searchButton{
        width: 90% !important; 
    }
}
.searchButton{
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color:#e89246;
    font-size: 14px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    cursor: pointer;
    width: 60%; 
  }
  .searchButton:hover{
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); 
  }
@media only screen and (min-width: 1300px){
    .detail-btn{
        margin-right: 12rem !important;  
    }
    }

    @media only screen and (max-width: 768px) {
       .FloorPlanSection .cardFloor {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

    .floorCardTitle{
        margin-bottom: 0px !important;
        font-size: 16.5px;
        color: #6c757d !important; 
    }

    .divDescriPlans{
        margin-left: 7rem;
        padding-left: 1.50rem;
        border-left: 4px solid  #e89246;
        padding-left: 2rem;
        width: 50%;
        margin-bottom: 3rem;
        /* margin-top: -0.5rem; */
        text-align: justify;
    }  
    .descrMainFloor{
        font-size: 16px;
        font-family: 'Poppins';
        text-align: justify;
    }
    .detailDivUpperDiv{
        display: flex;
        justify-content: space-between;
        padding-top:4vh;
        padding-bottom:2vh;
        padding-bottom: 3vh;
    }
    .imgDivFloorCaarousel{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85%;
    }
    @media only screen and (max-width: 500px) and (min-width: 200px){  
        .floorIntro {
          padding-left: 1rem !important;
          padding-right: 1rem !important;  
          margin-bottom: 1rem !important;
        }
        .site-button2{
            font-size: 16px !important;
        }
        .FloorPlanSection .cardFloor{
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
      }
      @media only screen and (max-width: 992px) and (min-width: 768px){  
        .FloorPlanSection .projectOpenSection{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin-left: 0rem !important;
            margin-right: 0rem !important;
        }
        .projectOpenSection .floorDivCarousel{
            height: 100% !important;
        }
        .projectOpenSection .floorDivCarousel ul{
            height: 95% !important;
        }
        .projectOpenSection .grid{
            grid-template-columns: repeat(2,180px);
        }
        .projectOpenSection .grid-2{
            grid-template-columns: repeat(2,180px);
        }
        .projectOpenSection .detail-btn{
           width: 60% !important;
        }
        .divDescriPlans{
            margin-left: 3rem !important;
            margin-right: 3rem !important;
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            width: 95% !important;
            margin-top: 0rem !important;
        }
        .searchButton{
            width: 90% !important;
        }
      }

      @media only screen and (max-width: 768px) and (min-width: 500px){  
        .FloorPlanSection .projectOpenSection{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin-left: 2rem !important;
            margin-right: 2rem !important;
        } 
      
        .divDescriPlans{
            margin-left: 2rem !important;
            margin-right: 3rem !important;
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            width: 95% !important;
            margin-top: 1rem !important;
        }
        .projectOpenSection .detail-btn{
            width: 50% !important;
            font-size: 14px !important;
         } 
         .detailDivUpperDiv{
            text-align: left !important;
        }
        .imgDivFloorCaarousel{
            height: 100%;
        }

      }

      @media only screen and (max-width: 500px) and (min-width: 250px){  
        .FloorPlanSection .projectOpenSection{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            margin-left: 0rem !important;
            margin-right: 0rem !important;
        } 
      
        .divDescriPlans{
            margin-left: 2rem !important;
            margin-right: 3rem !important; 
            padding-left: 1rem !important;
            padding-right: 2rem !important;
            width: 95% !important;
            margin-top: 1rem !important;
        }
        .projectOpenSection .detail-btn{
            width: 50% !important;
            font-size: 14px !important;
         } 
         .detailDivUpperDiv{
            text-align: left !important;
        }
        .imgDivFloorCaarousel{
            height: 100%;
        }
        .projectOpenSection .grid{
            grid-template-columns: repeat(2,140px);
        }
        .projectOpenSection .grid-2 {
            grid-template-columns: repeat(2,130px);
          
        }
        .backBtn{
            margin-bottom: 1rem !important;
            margin-top: 2rem !important;
            margin-left: 2rem !important;
        }

      }
      .backBtn:hover{
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);  
      }

      @media only screen and (max-width: 400px) and (min-width: 250px){  
        .projectOpenSection .carouselCol{  
            height: 60vh !important;
        }
        .projectOpenSection .floorDivCarousel{
            height: 100% !important;
        }
        .projectOpenSection .floorDivCarousel ul{
            height: 95% !important;
        }
        .projectOpenSection .detail-btn{
            width: 50% !important;
            padding: 10px 10px !important;
            font-size: 13px !important;
            margin: 0px !important;
            padding-right: 0px !important;
         } 
         .imgDivFloorCaarousel .imgCrausel{
            width: 80% !important;
         }
         .detailFlooBtnDiv1{
            font-size: 16px !important;
            padding: 10px 10px !important;
          }
          .searchButton{
            width: 90% !important;
          }

      }
      .imgDivFloorMain .card-img-top{
        height: 300px !important;
      }
      .BottomCardPart{
        display: flex;
        justify-content: space-between;
      }
      .IconFloorPart{
        display: flex;
        justify-content: space-between;
      }
      .SpanIconCardFloor{
        font-size: 14px;
        font-weight: 500;
      }
      .codeSpan{
        color: #e89246;
        margin: 0px !important;
        font-size: 15px;
        font-weight: 500;
      }
     .BottomCardPart .viewBtnCard{
        background-color: #e89246 !important;
        color: #fff !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        padding: 3px 5px !important;
        border-radius: 0px !important;
        margin: 0px !important;
      }
      .detailFlooBtnDiv1{
        background-color: #e7e7e7 !important;
        color: #3e3e3e;
        font-weight: 500;
        height: 50px;
        width: 30%;
        font-size: 20px;
        padding: 10px 15px;
        text-align: center;
      }

      @media (max-width: 576px){
        .DirectionFloor{
            width: 22vh !important;
        }
        .plotAreaFloor{
            width: 22vh !important;
        }
        .NumberFloor{
            width: 17vh !important;
        }
        .UnitFloor{
            width: 17vh !important;
        }
      }

      button:disabled {
        background: #e892468f;
      }




   
.aboutCount{
    background-image: url("https://www.nakshewala.com/static/media/counterBg.5978e39d886b6216c7b8.webp") !important;
    
}
.aboutCount .container {
    /* Your existing styles */
    padding-bottom: 55px;
    padding-top: 25px;
    max-width: 100% !important;
    
    position: relative !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    /* z-index: 0; */
  }
  



.aboutCount .count-box {
  padding: 20px 16px;
  width: max-content;
  margin: 30px 0px;
  background-color: white;
}

.aboutCount .count-box i {
  display: block;
  font-size: 48px;
  color: #a1bdd1;
  float: left;
  line-height: 0;
}

.aboutCount .count-box span {
  font-size: 28px;
  line-height: 25px;
  /* display: block; */
  font-weight: 700;
  color: #414042 ; 
  margin-left: 11px;
}

.aboutCount .count-box p {
  padding: 5px 0 0 0;
  margin: 0 0 0 60px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #414042 ;
}

.aboutCount .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #2e4b5e;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.aboutCount .count-box a:hover {
  color: #477392;
}

.aboutCount .content {
  font-size: 15px;
}

.aboutCount .content h3 {
  font-weight: 700;
  font-size: 24px;
  color: #263d4d;
}

.aboutCount .content ul {
  list-style: none;
  padding: 0;
}

.aboutCount .content ul li {
  padding-bottom: 10px;
  padding-left: 28px;
  position: relative;
}

.aboutCount .content ul i {
  font-size: 24px;
  color: #ff4a17;
  position: absolute;
  left: 0;
  top: -2px;
}

.aboutCount .content p:last-child {
  margin-bottom: 0;
}

.aboutCount .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#ff4a17 50%, rgba(255, 74, 23, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.aboutCount .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.aboutCount .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 74, 23, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.aboutCount .play-btn:hover::after {
  border-left: 15px solid #ff4a17;
  transform: scale(20);
}

.aboutCount .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.countEnqBtn{
    padding: 10px;
    border: 0px;
    background-color: #e89246;
    color: white;
    cursor: pointer;
}

.divCountBottom{
    margin-left: 7.9rem;
}


@media only screen and (max-width: 768px) and (min-width: 250px){  
    .divCountBottom{
        margin-left: 0rem !important;
    }
    .countEnqBtn{
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 470px) and (min-width: 250px){    
    .aboutCount .count-box {
        padding: 10px 10px !important;
    }
    .aboutCount .count-box i {
         font-size: 26px;
      }
      
      .aboutCount .count-box span {
         font-size: 14px;
      }
      
      .aboutCount .count-box p {
        font-size: 12px;
        margin: 0 0 0 4px; 
      }
}




