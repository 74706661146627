.testimonial {
  margin: 0 20px 40px;
}

.testimonial .testimonial-content {
  padding: 35px 25px 35px 50px;
  margin-bottom: 35px;
  background: #fff;
  position: relative;
}

.testimonial .testimonial-content:before {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 0;
  border-top: 15px solid #718076;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.testimonial .testimonial-content:after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 0;
  border-top: 15px solid #718076;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.testimonial-content .testimonial-icon {
  width: 50px;
  height: 45px;
  background: #e89246;
  text-align: center;
  font-size: 22px;
  color: #fff;
  line-height: 42px;
  position: absolute;
  top: 37px;
  left: -19px;
}

.testimonial-content .testimonial-icon:before {
  content: "";
  border-bottom: 16px solid #e89246;
  border-left: 18px solid transparent;
  position: absolute;
  top: -16px;
  left: 1px;
}

.testimonial .description {
  font-size: 15px;
  font-style: italic;
  color: #8a8a8a;
  line-height: 23px;
  margin: 0;
}

.testimonial .title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #525252;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin: 0 0 5px 0;
}

.testimonial .post {
  display: block;
  font-size: 14px;
  color: #e89246;
}
.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  background: #e89246 !important;
}
.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #e89246 !important;
  border-color: #e89246;
}

.slider {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}
.mainDiv {
  padding: 1rem !important;
}
.clientName {
  font-weight: 700;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: Lato, sans-serif;
}
.clientReview {
  font-family: Lato, sans-serif;
}
.reviewSection {
  background-color: #f0f4f8;
  position: relative;
  z-index: 3;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.videoReview {
  height: 216px !important;
}
.clientReviewHead {
  text-align: center;
  color: #222222;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .clientReviewHead {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
/* .react-multi-carousel-list {
        padding: 0rem 0 2rem 0;
        }
        
        .custom-dot-list-style button{
        border: none;
        background: #e89246 !important;
        }
        .react-multi-carousel-dot.react-multi-carousel-dot--active button{
        background: #e89246 !important;
        } */
