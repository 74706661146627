/* SliderComponent.css */

.OurTrustedPartnerSection {
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 6rem 0rem;
  }
  .OurTrustHead{
    margin: 0px;
    /* margin-top: 2rem; */
    margin-bottom: 55px;
    color: #222222;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
} 
  
  .slide {
    height: 100px;
    width: 250px;
  }
  .sliderReview{
    /* height: 110px; 
    width: 200px !important;     */
    
    padding: 10px !important;
    text-align: center;
    border: 1px solid #CCCCCC;
   
    /* box-shadow: 4px 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06) ;  */
    border-radius: 8px;
    overflow: hidden;
    margin-top: 0px !important;
    width: auto !important;
  }
  .sliderReview:hover{
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.16);  
  }
  .sliderReview img{
    vertical-align: middle;
    width: auto;
    max-width: 100%;
    height: 80px;
    display: inline-block;
  }  
  .CrauselTrustedPartner{
    /* justify-content: center; */
  }
  @media screen and (max-width: 500px) and (min-width: 250px) {
    .sliderReview img{
      height: 60px !important;
    }
    .sliderReview{
      padding: 10px 5px !important;  
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .OurTrustHead{
      font-size: 25px;
      margin-bottom: 30px;
    }
    .OurTrustedPartnerSection{
      padding: 2rem 0rem;
    }
    
  }







  

  
 