
.whySection {
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
  font-size: 16px;
  font-family: 'proxima nova', 'open sans', 'Helvetica', sans-serif;
  padding-top: 0px !important;
}
.all-partners {
    text-align: center;
    color: #222222;
    font-size: 30px;
    font-weight: bold;
    background-color: #f0f4f8;
    padding-top: 2rem;
}
.ImageDivMarquee{
    background: #fff;
    border-radius: 10px;
    width: 250px;
    justify-items: center;
    height: 180px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Marquee {
  /* background: linear-gradient(-135deg, #008ed9, #8b00db); */
  width: 85vw;
  box-sizing: border-box;
  padding: 1em;
  padding-bottom: 2em;
  padding-top: 2rem;
  color: white;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.Marquee-content {
  display: flex;
  /* animation: marquee 15s linear infinite running; */
  animation: marquee 65s linear infinite;     
}
.imgNameDivMar{
  display: flex;
  align-items: center;
  justify-content: center;
  /* grid-gap: 0.75rem;
  gap: 0.75rem; */
  flex-direction: column;
}
.Marquee-content:hover {
  animation-play-state: paused;
}

.Marquee-tag {
  width: 200px;
  margin: 0 .5em;
  padding: .5em;
  background: rgba(255, 255, 255, .1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;
}

.Marquee-tag:hover {
  background: rgba(255, 255, 255, .5);
  transform: scale(1.1);
  cursor: pointer;
}

/* @keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-30%);
  }
} */
@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }  
}

@media only screen and (max-width: 500px) and (min-width: 100px) {  
  .whySection{
    width: 100vw;
    justify-content: unset;
  }
  .Marquee {
    width: 100vw;
    padding: 0.5em;
    padding-bottom: 2em;
    padding-top: 2em;

  }
  .ImageDivMarquee{
    width: 150px;
    height: 150px;
    font-size: 14px;
    margin-right: 10px;
    padding-top: 2vh;
  } 
}
