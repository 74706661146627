.news
{
    text-align: center;
    padding-top: 40px ;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
}
.news .titletext
{
    text-align: center;
    font-size: 2em;
    /* padding-bottom: 40px; */ 
}
.news .titletext span
{
    color: #e89246; 
}

.containerPostCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2rem;
    gap: 2rem;
    background: var(--bg);
    border-radius: 20px;
    padding: 25px 0px;
    cursor: pointer; 
  }


  .PostCard { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #fff;
  }
  
  .Postcard__body { 
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    font-size: .75rem;
    background: var(--secondary);
    color: #fff; 
  }
  
  .tag + .tag { 
    margin-left: .5em; 
  }
  
  .Postcard__body h4 {
    font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        font-style: normal;
        text-align: left;
        color: #222;
  }

  .BlogCarousel{
    margin-top: -50px;
  }

  .centeredContentBlog {
    position: absolute;
    top: 60%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .centeredContentBlog h2{
    text-shadow: 0 2px 4px rgba(65,48,62,.3);
    font-size: 30px;
    font-weight: 500;
    /* line-height: 72px; */
    color: #fff;
    padding: 10px 20%;
  }
  .centeredContentBlog button{
    box-shadow: 0 2px 4px 0 rgba(65,48,62,.3);
    background-color: white;
    border-radius: 5px;
    font-size: 12px !important;
    font-weight: 500;
    /* letter-spacing: 1px; */
    line-height: 24px;
    padding: 10px 20px;
    text-transform: uppercase;
    text-align: center;
    color:#e89246;
    border: 2px solid #e89246;

  }

  @media only screen and (max-width: 576px) and (min-width: 100px) {  
    .centeredContentBlog button{
      padding: 10px 12px;  
    } 
    .centeredContentBlog h2{
      font-size: 6vw;
      color: #fff;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 576px) {  
    .centeredContentBlog h2{
      /* font-size: px !important; */
    }
  }

  .bannerNewImageDivBlog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(180deg,rgba(65,48,62,0),#000);     
    /* opacity: 0.5;  */
  }

  .bannerNewDivBlog {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bannerNewImageDivBlog {
    flex: 1;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 85vh;
  }


  /* For Blog Details Page */
  .detaSecBlog{
    padding: 20px 8%;
  }
  .BlogDetailMainDiv{
    display: grid;
    grid-template-columns: 1.9fr 1fr; /* Two equal-width columns */  
    grid-gap: 20px; /* Gap between grid items */
  }
  .blogForm{
    position: sticky;
    top: 120px;
    right: 20px;
    border: 1px solid black;
    margin: 5px !important;
    height: max-content;
    /* z-index: 1 !important; */
  }
  
  .contPostCardBody{
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    color: #666;
  }
  .blogCntImg{
      padding: 20px;
      border-radius: 5px;
  }
  .BlogDiv2{
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 992px) and (min-width: 250px) {  
    .detaSecBlog{
      padding: 20px 2% !important;
    }
  }
  
  @media only screen and (max-width: 768px) and (min-width: 576px) {  
    .BlogDetailMainDiv {
      display: grid;
      grid-template-columns:60%  40%;
      grid-gap: 10px;
  }

  }  
  @media only screen and (max-width: 576px) and (min-width: 230px) {  
    .BlogDetailMainDiv {
      display: block !important;
  }
  .blogCntImg{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .BlogCntMainDivCar .react-multiple-carousel__arrow{    
    display: none;
  }
  

  }

  .PostCard:hover{
    box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 15px 10px rgba(0,0,0,.1);
    cursor: pointer;
  }
  .Postcard__header{
    height: 215px;
  }

  .Postcard__header img{
    height: 100%;
  }


