@import url(https://fonts.googleapis.com/css?family=Lato:400,600,700);
.sliderReels {
  /* margin:0 20px; */
  overflow: "hidden";
  padding: 2rem 0;
  width: 97%;
  margin-top: -22px;
}
.reviewSectionReels {
  background-color: #f0f4f8;  
  position: relative;
  z-index: 3;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.PortNewHeadReels {
  text-align: justify;
  color: #222222;
  font-size: 35px;
  font-weight: 700;
  /* padding-top: 2rem; */
  padding-left: 80px;
  padding-right: 80px; 
}
@media (max-width: 768px) {
  .PortNewHeadReels {
    font-size: 25px;
    padding-bottom: 10px;
    padding-left: 64px;
    padding-right: 20px;
  }
  .parentReels {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (max-width: 500px) {
  .imageReels {
    height: 620px !important;
  }
}
.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

/* .custom-dot-list-style button{
    border: none;
    background: #e86322;
    }
    .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    background: #e86322 !important;
    } */

.snip1527 {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  float: left;
  font-family: "Lato", Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  /* max-width: 310px; */
  /* min-width: 250px; */
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 1rem;
}
.imageReels {
  width: 100%;
  height: 450px;
}
.snip1527 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.snip1527 .VideoReels {
  width: 100%;
  vertical-align: top;
  position: relative;
  height: 100%;
  object-fit: cover;
}
/* .snip1527:hover img,
.snip1527.hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */
.imgInstaLogo {
  width: 80px;
  height: 80px;
  margin: 0 0 10px 0;
  /* background: linear-gradient(45deg, #fc8720, #8f32c3); */
  border: 1px solid transparent;
  /* border-image: linear-gradient(45deg, #fc8720, #8f32c3) 1; */
  box-shadow: 0 0 0 1px transparent, 0 0 0 2px #fc8720, 0 0 8px 1px #8f32c3;
  border-radius: 50% !important;
  padding: 8px;
}
.InstaBtn {
  padding: 12px 24px;
  border: 1px solid #e89246;
  color: #e89246;
  background: #fff;
  border-radius: 10px;
}
.InstaBtn:hover {
  border: 1px solid #e89246;
  color: #fff;
  background: #e89246;
  /* box-shadow: 2px 2px 6px 0px ; */
  box-shadow: 0px 0px 8px #e89246;
}

.cardServices{
  margin-right: 24px;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.3);
  text-align: center;
  border-radius: 12px;
}
.cardServices img{
  width: 100%;
  height: 234px;
 border-top-right-radius: 12px;
 border-top-left-radius: 12px;
}
.texts{
  padding: 1.5rem;
   margin-bottom:10px;
}
.texts button{
  border: none; padding: 8px 15px;
  background: #e89246;
   color: #fff; 
  font-weight: 600; 
  border-radius: 24px;
  transition: all 0.3s ease-in;
}
.texts button:hover{
background: #E0E3DA;
color: #e89246; 
cursor: pointer;
}
.servicesNewReels {
  text-align: justify;
  color: #222222;
  font-size: 35px;
  font-weight: 700;  
  padding-top: 1rem;
  padding-left: 10vw;
  padding-right: 10vw;
}

