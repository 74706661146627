.propertyModalContent {
  margin-top: 5%;
  padding-bottom: 1%;
}
.title-d {
  margin-bottom: 23px;
  margin-top: 20px;
}
.UlModal{
  padding-left: 0px !important;
}
@media screen and (max-width: 849px) and (min-width: 611px) {
  .propertyModalContent {
    margin-top: 8%;
  }
}
@media screen and (max-width: 611px) and (min-width: 471px) {
  .propertyModalContent {
    margin-top: 10%;
  }
}
@media screen and (max-width: 471px) and (min-width: 375px) {
  .propertyModalContent {
    margin-top: 13%;
  }
}
@media screen and (max-width: 375px) and (min-width: 290px) {
  .propertyModalContent {
    margin-top: 16%;
  }
}
@media screen and (max-width: 290px) and (min-width: 150px) {
  .propertyModalContent {
    margin-top: 23%;
  }
}
