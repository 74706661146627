@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.rowGetQuote{
  margin-left: 3% !important;
  margin-right: 3% !important;
  margin-top: 14px  !important;
  margin-bottom: 4px  !important;
}
.getQuoteLabel {
  font-size: 1.5vw !important;
  padding-top: 2vh !important;
  padding-bottom: 1vh; 
}
.form-radio-select {
  margin-left: 3%;  
  margin-right: 4%;
}
.detailDivGet {
  padding: 1rem;
  height: 100% !important;
  /* background: linear-gradient(68deg,#34b9c2 8%,#1c657b 80%,#246d90 100%); */
  background: #e89246;
}
.mainGet {
  display: grid;
  /* padding: 1rem; */
  grid-template-columns: 40% 1fr;
  /* gap: 1rem; */
  align-items: center;
  max-width: 95%;
  margin: 5% auto;
  font: 500 100%/1.5 system-ui;
  /* background: linear-gradient(68deg,#FF4433 8%,#E35335 80%,#FF4433 100%); */
  /* background: linear-gradient(68deg,#34b9c2 8%,#1c657b 80%,#246d90 100%); */
}

.HeadingNameGet {
  font-size: 22px;
  font-weight: 700;
  /* color:#000000; */
  color: #fff;
  text-align: center !important;
}
.description {
  font-size: 12px;
  margin-top: 8px !important;
}
.bookButtonGet {
  /* border: 1px solid #1d8e9e; */
  background-color: #fff;
  border-radius: 2px;
  width: 45%;
  padding: 5px;
  color: #41303e;
  border-radius: 5px;
}
.bookButtonGet:hover {
  background-color: #1d8e9e;
  border: 1px solid #1d8e9e;
  color: white;
}

.imgServiceModalGet {
  width: 100%;
  height: 549px;
  /* box-shadow: 2px 2px 42px 2px #ddd; */
}
.social-links {
  display: flex;
  align-items: center;
}

.InputTagHead {
  font-size: 14px;
  color: #fff;
}
.ShareDesign {
  font-size: 16px;
}
.FacebookIcon {
  margin-left: 0px !important;
}
@media (max-width: 1000px) {
  .mainGet {
    display: block;
  }
  /* p {
    position: relative;
    background-color: #ffffff85
  } */
  .imgServiceModal {
    width: 100%;
    background-position: center;
    object-fit: cover;
  }
}

.option {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + span {
    background-color: #cea779;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      background-color: #cea779;
      color: #fff;
    }
  }

  .btn-option {
    margin: 0 10px 10px 0;
    display: flex;
    color: #666;
    background-color: #fff;
    border-radius: 5px;
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
    }
  }
}
.btnGrpDiv {
  border-radius: 0;
  box-shadow: none;
}
.btnGrpDiv :hover {
  box-shadow: none !important;
}
.RadioBtnDiv:focus ~ span {
  width: 92%;
}

.inputTextGet {
  display: block;
  outline: none;
  border: none;
  height: 2em;
  font-size: 16px;
  margin-bottom: 1px;
  border-bottom: 1px solid #333;
  border-radius: 0 !important;
  /* color: #666; */
  color: #fff;
  background-color: transparent;
  padding: 5px;
}

input:focus {
  border-bottom: 1px solid #0572ce;
  /* box-shadow: 0 1px 0 0 #0572ce; */
}

.btn-group:hover {
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .radio-group {
    display: inline-block;
    margin-top: 5px;
    overflow: hidden;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .radio-group {
    display: inline-block;
    margin-top: 10px;
    overflow: hidden;
  }
 
}
@media (min-width: 270px) and (max-width: 479px) {
  .radio-group {
    display: inline-block;
    margin-top: 5px;
    overflow: hidden;
  }


}
@media (min-width: 480px) and (max-width: 767px) {
  .radio-group {
    display: inline-block;
    margin-top: 8px;
    overflow: hidden;
  }
}

.radio-group {
  display: inline-block;
  margin-top: 15px;
  overflow: hidden;
}

/* @media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-gp {
    display: inline-block;
    margin-right: 5px;
  }
}

.form-radio-gp {
  display: inline-block;
  margin-right: 8px;
}

.form-radio-gp {
  margin-bottom: 1%;
} */

@media (min-width: 480px) and (max-width: 767px) {
  /* .form-radio-gp {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  } */
  .getQuoteLabel {
    font-size: 2vw !important;
  }
}
/* @media (max-width: 479px) and (min-width: 270px) {
  .form-radio-gp {
    margin-right: 5px !important;
    display: inline-block;
  }
} */

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-gp input[type="radio"] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
}

.form-radio-gp input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-radio-gp input[type="radio"] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  .getQuoteLabel {
    font-size: 2vw !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .form-radio-gp input[type="radio"] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  .form-radio-select {
    margin-left: 7% !important;
    margin-right: 4%;
  }
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-radio-gp input[type="radio"] {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  .form-radio-select {
    margin-left: 7% !important;
    margin-right: 4%;
  }
}

.form-radio-gp label {
  width: 100% !important;
  color: #eba86c !important;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  padding: 3px 0px;
  /* padding-top: 4px; */
  border-radius: 3px !important;
  border: 1.6px solid #eba86c;
  font-size: 11px !important;
  margin: 0;
  text-align: center !important;

}
.form-radio-gp input {
  width: 90% !important;
}
.form-radio-gp{
  padding-left: 0rem !important;
  margin-bottom: 5px;
  /* padding-right: 0rem !important; */
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-gp label {
    color: #eba86c;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    /* padding: 4px 15px; */
    border: 1.6px solid #eba86c;
    font-size: 12px;
    /* border-radius: 5px; */
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-radio-gp label {
    color: #eba86c;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    /* padding: 0px 15px; */
    border: 1.6px solid #eba86c;
    font-size: 10px !important;
    /* border-radius: 5px; */
    margin: 0;
  }

}

@media (min-width: 480px) and (max-width: 767px) {
  .form-radio-gp label {
    color: #eba86c;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    /* padding: 0px 12px;  */
    border: 1.6px solid #eba86c;
    font-size: 10px !important;
    /* border-radius: 5px; */
    margin: 0;
  }
}

@media (max-width: 479px) and (min-width: 270px) {
  .form-radio-gp label {
    font-size: 12px;
  }
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-radio-gp label {
    color: #eba86c;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    /* padding: 0px 6px; */
    border: 1px solid #eba86c;
    font-size: 9px !important;
    /* border-radius: 5px; */
    margin: 0;
  }
}

.form-radio-gp input[type="radio"]:checked + label {
  color: #fff !important;
  background: #e89246;
  border: 2px solid #e89246;
}

.form-radio-message {
  margin-top: 5px;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-message {
    margin-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-radio-message {
    margin-top: 0;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .form-radio-message {
    margin-top: -5px;
  }
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-radio-message {
    margin-top: -5px;
  }
}

.form-radio-message p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  color: #0f0f10;
  font-size: 14px;
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-radio-message p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    color: #0f0f10;
    font-size: 12px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .form-radio-message p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    color: #0f0f10;
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-radio-message p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    color: #0f0f10;
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-message p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    color: #0f0f10;
    font-size: 14px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-select-message {
    flex: 1;
    padding-left: 5px;
    padding-top: 5px;
  }
  .form-radio-label {
    color: #848484;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    margin-left: 3%;
  }
  .rowGetQuote{
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

.form-select-message {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-select-message {
    flex: 1;
    padding-left: 5px;
    padding-top: 5px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .form-select-message {
    flex: 1;
    padding-left: 5px;
    padding-top: 5px;
  }
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-select-message {
    flex: 1;
    padding-left: 5px;
    padding-top: 5px;
  }
}

.form-radio-label {
  color: #848484;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-top: 10px;
  margin-left: 3%;
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .form-radio-label {
    color: #848484;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    margin-left: 3% !important;
  }
  .rowGetQuote{
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .form-radio-label {
    color: #848484;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    margin-left: 3% !important;
  }
  .rowGetQuote{
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .form-radio-label {
    color: #848484;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    margin-left: 6% !important;
  }
  .rowGetQuote{
    margin-left: 6% !important;
    margin-right: 6% !important;
  }
}

@media (min-width: 270px) and (max-width: 479px) {
  .form-radio-label {
    color: #848484;
    font-size: 9px;
    font-weight: 500;
    line-height: 1;
    margin-top: 10px;
    /* margin-left: 6% !important; */
  }
}

.form-radio-label p {
  line-height: 1;
  margin-bottom: 0;
  font-weight: 500;
  color: #414042;
}

::placeholder {
  color: #fff;
  opacity: 1; /* Firefox */
}


.getaQuoteModalDiv4 {
  width: 75%;
  margin-left: 11%;
  margin-right: 11%;
}
.getModalDiv2Portion {
  justify-content: left;
  align-items: start;
}
@media only screen and (max-width: 1000px) and (min-width: 768px) {
  .closebtnforLoginUp button{
    font-size: 2vw !important;   
  }
  .closebtnforLoginUp .closeBtn{
    font-size: 2vw !important;   
  }
  
  .loginPopContainer div{
    width: 25vh !important;
    height: 16vh !important;
  }
  .loginPopContainer .imgDiv{
    height: 7vh !important;
  }
  .loginPopContainer h4{
    font-size: medium;
  }
  .loginPopContainer h5{
    font-size: medium;
  }
  .loginPopContainer h1{
    font-size: xx-large;
    margin-top: -3vh;
  }
 
}
@media only screen and (max-width: 500px) and (min-width: 10px) {
  .closebtnforLoginUp button{
    font-size: 3vw !important;   
  }
  .closebtnforLoginUp .closeBtn{
    font-size: 6vw !important;
    margin-top: -2vh !important;   
    color: black !important;   
  }
  .loginPopContainer div{
    width: 23vh !important;
    height: 16vh !important;
  }
  .loginPopContainer h4{
    font-size: x-small; 
  }
  .loginPopContainer h1{
    font-size: x-large;  
    margin-top: -3vh;
  }
  .loginPopContainer h5{
    font-size: small;
  }
  .loginPopContainer .imgDiv{
    height: 7vh !important;
  }
}
@media only screen and (max-width: 350px) and (min-width: 10px) {
  .closebtnforLoginUp button{
    font-size: 4vw !important;   
  }
  .closebtnforLoginUp .closeBtn{
    font-size: 7vw !important;   
  }
  .loginPopContainer div{
    width: 20vh !important;
    height: 13vh !important;
  }
  .loginPopContainer h4{
    font-size: x-small; 
  }
  .loginPopContainer h1{
    font-size: x-large;  
    margin-top: -3vh;
  }
  .loginPopContainer h5{
    font-size: small;
  }
  .loginPopContainer .imgDiv{
    height: 7vh !important;
  }
  .loginModalDiv1portion input  {
    font-size: 3.5vw !important;
}
}
@media only screen and (max-width: 10000px) and (min-width: 1000px) {
  .closebtnforLoginUp button{
    font-size: 1.5vw !important;   
  }
  .closebtnforLoginUp .closeBtn{
    font-size: 1.5vw !important;    
  }
  .loginPopContainer h5{
    font-size: x-large;
  }
  .loginPopContainer h4{
    font-size: x-large; 
  }
  .loginPopContainer .imgDoubleQuote{
    font-size: xxx-large;  
    margin-top: -3vh;
  }
  .loginPopContainer .imgDiv{
    height: 7vh !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 500px) {
  .closebtnforLoginUp .closeBtn{
    font-size: 3vw !important;   
  }
  .loginPopContainer div{
    width: 25vh !important;
    height: 16vh !important;
  }
  .loginPopContainer h4{
    font-size: medium;
  }
  .loginPopContainer h1{
    font-size: xx-large;  
    margin-top: -3vh;
  }
  .loginPopContainer h5{
    font-size: medium;
  }
  .loginPopContainer .imgDiv{
    height: 7vh !important;
  }
}

.ReactModal__Overlay{
  background-color: #dee2e629 !important;
  backdrop-filter: blur(2px);
}

@media (max-width: 768px) {  
  .closebtnforLoginUp button{
    margin-left: 0vh !important;
  }
  .pImageGetQuote{
    font-size: 3vw !important;
  }
}


@media only screen and (max-width: 500px) and (min-width: 100px) { 
  .rowGetQuote{
    margin-right: 0% !important;
    margin-top: 10px !important;
  }
  .getQuoteLabel{
    font-size: 6.3vw !important;
  }
  .form-radio-gp label{
    font-size: 12px !important;
  }
  .form-radio-label{
    font-size: 4vw !important;
  }
}





