@media only screen and (max-width: 1200px) and (min-width: 990px) {
  .react-calendar {
    width: 280px;
  }
  .react-calendar__navigation button {
    min-width: 42px;
    background: none;
    font-size: 14px !important;
  }

  .react-calendar__tile {
    font-size: 11px !important;
  }
  .react-calendar__month-view__weekdays {
    font-size: 9px;
  }
}

@media only screen and (max-width: 450px) and (min-width: 375px) {
  .react-calendar {
    width: 250px;
  }
  .react-calendar__navigation button {
    min-width: 40px;
    background: none;
    font-size: 12px !important;
  }

  .react-calendar__tile {
    font-size: 9px !important;
  }
  .react-calendar__month-view__weekdays {
    font-size: 7px;
  }
}
@media only screen and (max-width: 375px) and (min-width: 300px) {
  .react-calendar {
    width: 200px;
  }
  .react-calendar__navigation button {
    min-width: 40px;
    background: none;
    font-size: 12px !important;
  }

  .react-calendar__tile {
    font-size: 9px !important;
  }
  .react-calendar__month-view__weekdays {
    font-size: 7px;
  }
}
@media only screen and (max-width: 300px) and (min-width: 250px) {
  .react-calendar {
    width: 170px;
  }
  .react-calendar__navigation button {
    min-width: 35px;
    font-size: 10px !important;
  }
  .react-calendar__month-view__weekdays {
    font-size: 7px;
  }
  .react-calendar__tile {
    font-size: 9px !important;
  }
}
@media only screen and (max-width: 249px) and (min-width: 50px) {
  .react-calendar {
    width: 140px;
  }
  .react-calendar__navigation button {
    min-width: 30px;
    font-size: 8px !important;
  }
  .react-calendar__month-view__weekdays {
    font-size: 6px;
  }
  .react-calendar__tile {
    font-size: 5px !important;
  }
}
.profilePageUserIcon {
  background-color: #808080;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 100%;
  font-size: 3rem;
  color: white;
  margin-bottom: 3vh;
}

.profileUserNameCard {
  margin-left: 20px;
  /* background-color: rgb(115, 115, 115,0.6); */
  background-image: url("https://i.pinimg.com/originals/38/bc/cf/38bccf37953ac4d2c9d7eb45859450ca.gif");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 100% 40%;
  position: relative;
  opacity: 0.9;
}

.editSocialIcon {
  font-style: normal !important;
  font-size: 23px;
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.7s;
  margin-bottom: 20px;
}

.editSocialIcon:hover {
  border-radius: 5px;
  color: rgb(51, 51, 247);
  text-shadow: 3px 3px 2px #bb2893;
  rotate: 360deg;
  transform: scale(1.5);
  background-color: transparent;
}

.profileOptionsli {
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 11px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid rgb(204, 204, 204);
  transition: all 0.2s;
}

.profileOptionsli:hover {
  background-color: #2c2c2c;
  color: white;
}

.signOutModal {
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(238, 238, 238, 0.6);
  left: 0;
  right: 0;
  z-index: 99;
}

.signOutModalDiv button {
  cursor: pointer;
  margin: 10px;
  padding: 7px;
  font-weight: 600;
  border: 1px solid rgb(181, 181, 181);
  background-color: white;
  color: rgb(117, 116, 116);
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 3px;
  transition: all 0.5s;
}

.signOutModalDiv button:hover {
  background-color: #ffda25;
  color: black;
  box-shadow: 1px 1px 10px #ffda25;
  border: 1px solid transparent;
}

.mainPageloginButtonforLoginModal {   
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  color: Black;
  padding: 10px;
  border-radius: 17px;
  padding-top: 2px;
  padding-bottom: 7px;
  transition: all 0.5s;
}


.mainPageloginButtonforLoginModal:hover {
  transition: all 0.5s;
  color: black;
  background-color: #e86322;
  /* width: fit-content !important; */
}

.displayFlex div {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.displayFlex input {
  max-width: 90%;
  max-height: 5vh;
  padding: 4px;
  background-color: transparent;
  color: black;
  border: 1px solid rgb(123, 123, 123);
  border-radius: 4px;
}

.profileInputs div {
  margin-top: 2vh;
}

.profileInputs input {
  font-size: 0.8rem !important;
  max-height: 5vh;
  padding: 4px;
  background-color: transparent;
  color: black;
  width: 80%;
  margin-left: 2vh;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 2px;
  transition: all 0.2s;
}

.profileInputs input:hover {
  cursor: text;
  border: 1px solid transparent;
  box-shadow: 1px 1px 10px #807e7e;
}

.profileInputs input:focus {
  border: 1px solid transparent;
  box-shadow: 1px 1px 10px #807e7e;
}

.profileInputs div h6 {
  font-size: 0.8rem !important;
  padding-left: 1vh;
}

.displayFlex input:hover {
  cursor: text;
  border: 1px solid black;
  box-shadow: 1px 1px 10px #ffda25;
}

.displayFlex input:focus {
  outline: black;
  border: 1px solid black;
  box-shadow: 1px 1px 10px #ffda25;
}

.loginModalDiv1 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #dee2e629;
  /* background-color: rgb(107 107 107 / 60%); */
  z-index: 999999;
  backdrop-filter: blur(2px);
}

.loginModalDiv2 {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  border: 1px solid transparent;
  background-color: white;
  border-radius: 7px;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0px 0px 12px black;
}

.loginModalDiv3 {
  padding-left: 25px;
  padding-right: 25px;
}

.loginModalDiv3 input {
  background-color: transparent;
  border: 1px solid rgb(171, 170, 170);
  padding: 6px;
  max-height: 6vh;
  border-radius: 6px;
  color: black;
  transition: all 0.4s;
}

.loginModalDiv3 div {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
}

.loginModalDiv3 textarea {
  background-color: transparent;
  border: 1px solid rgb(171, 170, 170);
  padding: 4px;
  min-height: 2rem;
  color: black;
  border-radius: 6px;
}

.loginModalDiv3 input:focus {
  border: 1px solid black;
  border: 1px solid black;
  box-shadow: 0px 0px 3px #ffda25;
}

.loginModalDiv3 button {
  background-color: #2c2c2c;
  color: white;
  border: 1px solid transparent;
  width: 100%;
  text-align: center;
  margin-top: 1vh;
  border-radius: 3px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.6s;
}

.loginModalDiv3 button:hover {
  background-color: #ffda25;
  color: black;
  border: 1px solid transparent;
  box-shadow: 1px 1px 2px #ffda25;
}

.loginModalDiv3 input:hover {
  border: 1px solid black;
  box-shadow: 0px 0px 3px #ffda25;
}

.overff::-webkit-scrollbar {
  width: 1px;
  color: transparent;
  background-color: transparent;
}

.overff::-webkit-scrollbar-thumb {
  background-color: transparent;
  color: transparent;
}
.overff2::-webkit-scrollbar {
  width: 6px;
  color: transparent;
  background-color: transparent;
}

.overff3::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  color: transparent;
}
.overff3::-webkit-scrollbar {
  width: 2px;
  color: transparent;
  background-color: transparent;
}

.overff3::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  color: transparent;
}
.closebtnforLoginUp button {
  /* margin-top: 1vh; */
  margin-left: 3vh;
  cursor: pointer;
  border: none !important;
  color: whitesmoke;
  font-size: 1.2rem;
  background-color: transparent;
  font-weight: 900;
  transition: all 0.2s;
  position: fixed;
  z-index: 3;
  border-radius: 8px;
  padding-top: 6px;
}

.closebtnforsignUp button {
  cursor: pointer;
  border: none !important;
  color: black;
  background-color: transparent;
  font-weight: 800;
  transition: all 0.2s;
  position: fixed;
  z-index: 999;
  border-radius: 8px;
}

.closebtnforLoginUp button:hover {
  color: red !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  transform: scale(1.1);
}

.LoginModalDiv4 {
  margin-top: 15vh;
  width: 56%;
  margin-left: 24%;
  margin-right: 24%;
  height: 60vh;
  background-color: transparent;
  border: 1px solid white;
  max-height: 90vh;
  border-radius: 4px;
}
.LoginModal2Div4 {
  /* margin-top: 15vh;
      width: 40%;
      margin-left: 30%;
      margin-right: 30%; */
  background-color: white;
  border: 1px solid transparent;
  max-height: 90vh;
  border-radius: 4px;
  box-shadow: 0px 0px 12px rgb(227, 227, 227);
}

.signUpMODALMAIN {
  margin-top: 15vh;
  overflow: auto !important;
  width: 86%;
  margin-left: 7%;
  margin-right: 7%;
  height: 75vh;
  background-color: transparent;
  /* border: 1px solid transparent; */
  max-height: 80vh;
  border-radius: 4px;
  box-shadow: 0px 0px 12px #807e7e;
}

@media only screen and (max-width: 1200px) and (min-width: 250px) {
  .col-xl-8 {
    margin-top: 20px;
  }
}
/* calendar bug fix 5 */
/* @media screen and (max-width:480px ) and (min-width:250px){
    .react-calendar {
     background: #fff;      
     border: 1px solid #a0a096;
     font-family: Arial,Helvetica,sans-serif;    
     line-height: 1.125em;
     max-width: 100%;
     width: 310px;  
     }
   
    } */
/* calendar bug fix 5 */
@media only screen and (max-width: 500px) and (min-width: 10px) {
  .registerFormModal {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
  .imagesProject img {
    border-radius: 11px;
    height: 50vw;
    transition: all 0.3s;
    width: 74vw;
  }
  .LoginModalDiv4 {
    margin-top: 25vh;
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
    border: 1px solid transparent;
    /* max-height: 60vh; */
    height: 60vh;
    border-radius: 4px;
  }
  .commonclass {
    margin-left: -53%;
    font-size: 6vw !important;
  }
  .loginModalDiv1portion {
    min-height: 60vh;
    margin-left: 0rem !important;
    margin-right: 0.3rem !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .loginModalDiv1portion input,select,
  button {
    font-size: 4.5vw !important;
  }

  .loginLabel {
    font-size: 3vw !important; 
  }

  .loginModalforgotpasswordSpan,
  .loginModalSignUp {
    font-size: 1.5vw !important;
  }

  .signUpMODALMAIN input {
    font-size: 3vw !important;
  }

  .signUpMODALMAIN {
    margin-top: 20vh;
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
    height: 60vh;
    background-color: transparent;
    /* border: 1px solid transparent; */
    max-height: 80vh;
    border-radius: 4px;
    box-shadow: 0px 0px 12px #807e7e;
  }

  .signupDivPortion1 input,
  .signupDivPortion2 input,
  .signupDivPortion1 textarea {
    font-size: 3.4vw !important;
    background-color: black !important;
  }

  .signupgenlabel {
    font-size: 3vw;
  }
  .uploadfileModal {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 25vh;
    position: fixed;
    overflow: hidden;
    background-color: rgba(240, 238, 238, 0.6);
    z-index: 999 !important;
  }
  .uploadfileDiv {
    margin-left: 10% !important;
    margin-right: 10% !important;
    width: 80% !important;
  }

  .drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  }

  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }

  .drop-container:hover .drop-title {
    color: #222;
  }

  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color 0.2s ease-in-out;
  }
  .signOutModalDiv {
    text-align: center;
    padding: 30px;
    margin-top: 25vh;
    border: 1px solid transparent;
    background-color: white;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 5px;
    /* z-index: 99 !important;       */
  }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  .imagesProject img {
    border-radius: 11px;
    height: 45vw;
    transition: all 0.3s;
    width: 75vw;
  }
  .LoginModalDiv4 {
    margin-top: 15vh;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    border: 1px solid transparent;
    /* max-height: 60vh; */
    height: 80vh;
    border-radius: 4px;
  }
  .commonclass {
    margin-left: -40%;
    /* font-size: 6vw !important; */
  }
  .loginModalDiv1portion {
    min-height: 60vh;
  }

  .loginModalDiv1portion input,
  button,select {
    font-size: 1.5vw !important;
  }

  .loginLabel {
    font-size: 3vw !important;
  }
  .registerLabel {
    font-size: 2vw !important;
  }

  .loginModalforgotpasswordSpan,
  .loginModalSignUp {
    font-size: 1vw !important;
  }

  .signUpMODALMAIN input {
    font-size: 1.9vw !important;
  }

  .signUpMODALMAIN {
    margin-top: 15vh;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    background-color: transparent;
    /* border: 1px solid transparent; */
    max-height: 80vh;
    border-radius: 4px;
    box-shadow: 0px 0px 12px #807e7e;
  }

  .signupDivPortion1 input,
  .signupDivPortion2 input,
  .signupDivPortion1 textarea {
    font-size: 2vw !important;
  }

  .signupgenlabel {
    font-size: 2.6vw;
  }
  .signOutModalDiv {
    text-align: center;
    padding: 30px;
    margin-top: 25vh;
    border: 1px solid transparent;
    background-color: white;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 5px;
    /* z-index: 99 !important;       */
  }
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
  .imagesProject img {
    border-radius: 11px;
    height: 17vw;
    transition: all 0.3s;
    width: 18vw;
  }
  .LoginModalDiv4 {
    margin-top: 15vh;   
    width: 60%;      
    margin-left: 20%;  
    margin-right: 20%;
    border: 1px solid transparent;
    /* min-height: 60vh;
    max-height: 80vh; */
    height: 80vh;
    border-radius: 4px;
  }
  .commonclass {
    margin-left: -30%;
  }
  .loginModalDiv1portion {
    min-height: 60vh;
  }
 

  .loginModalDiv1portion input,
  button, select {
    font-size: 1.5vw !important;
  }

  .loginLabel {
    font-size: 2.5vw !important;
  }
  .registerLabel {
    font-size: 2vw !important;
  }

  .loginModalforgotpasswordSpan,
  .loginModalSignUp {
    font-size: 1vw !important;
  }

  .signUpMODALMAIN input {
    font-size: 1.5vw !important;
  }

  .signUpMODALMAIN {
    margin-top: 15vh;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    background-color: transparent;
    /* border: 1px solid transparent; */
    max-height: 80vh;
    border-radius: 4px;
    box-shadow: 0px 0px 12px #807e7e;
  }

  .signupDivPortion1 input,
  .signupDivPortion2 input,
  .signupDivPortion1 textarea {
    font-size: 2vw !important;
  }

  .signupgenlabel {
    font-size: 2vw;
  }
  .signOutModalDiv {
    text-align: center;
    padding: 30px;
    margin-top: 25vh;
    border: 1px solid transparent;
    background-color: white;
    margin-left: 25%;
    margin-right: 25%;
    border-radius: 5px;
    /* z-index: 99 !important;       */
  }
  .closeBtn{
    font-size: 2vw !important;
  }
}

@media only screen and (max-width: 10000px) and (min-width: 1000px) {
  .imagesProject img {
    border-radius: 11px;
    height: 17vw;
    transition: all 0.3s;
    width: 19vw;
  }
  .signUpMODALMAIN {
    margin-top: 15vh;
    width: 56%;
    background-color: white;
    margin-left: 23%;
    margin-right: 23%;
    min-height: 66vh;
    height: 30vw;
    border-radius: 4px;
    box-shadow: 0px 0px 12px #807e7e;
  }
  .LoginModalDiv4 {
    margin-top: 15vh;
    width: 65%;
    margin-left: 16%; 
    margin-right: 22%;
    border: 1px solid transparent;
    height: max-content;
    /* max-height: 90vh; */
    border-radius: 4px;
  }
  .commonclass {
    margin-left: -22%;
    width: 20% !important;
  }
  .loginModalDiv1portion {
    min-height: 60vh;
  }

  .alreadySignin {
    font-size: 1rem !important;
    color: black;
  }
  .alreadySignin:hover {
    color: blue;
    font-weight: 600;
  }

  .signupDivPortion1 input,
  .signupDivPortion2 input,
  .signupDivPortion1 textarea {
    font-size: 1vw !important;
  }

  .signupgenlabel {
    font-size: 1.4vw;
  }
  .signOutModalDiv {
    text-align: center;
    padding: 30px;
    margin-top: 25vh;
    border: 1px solid transparent;
    background-color: white;
    margin-left: 30%;
    margin-right: 30%;
    border-radius: 5px;
    /* z-index: 99 !important;       */
  }
}

.loginIconImage img {
  padding-top: 10%;
  padding-left: 2vh;
  padding-right: 2vh;
}

.LoginModalDiv4 input {
  background-color: transparent;
  border: 1px solid rgb(171, 170, 170);
  padding: 1px;
  border-radius: 2px;
  color: black;
  font-size: 12px;
  padding-left: 4px;
  margin: 7px;
  transition: all 0.4s;
}
.LoginModal2Div4 input {
  background-color: transparent;
  border: 1px solid rgb(171, 170, 170);
  padding: 1px;
  border-radius: 2px;
  color: black;
  font-size: 12px;
  padding-left: 4px;
  margin: 7px;
  transition: all 0.4s;
}
.LoginInputsDiv5 {
  font-size: 16px;
  width: 100%;
}

.LoginModalDiv4 input:hover {
  /* border: 1px solid rgb(87, 85, 85); */
  cursor: text;
  border: 1px solid #c1bac8;
  box-shadow: 0px 0px 9px #c1bac8;
}
.LoginModal2Div4 input:hover {
  /* border: 1px solid rgb(87, 85, 85); */
  cursor: text;
  border: 1px solid #c1bac8;
  box-shadow: 0px 0px 9px #c1bac8;
}

.LoginModalDiv4 input:focus {
  border: 1px solid #c1bac8;;
  box-shadow: 0px 0px 6px #c1bac8;  
}
.LoginModal2Div4 input:focus {
  border: 1px solid #c1bac8;
  box-shadow: 0px 0px 6px #c1bac8;    
}

.LoginModalDiv4 button:hover {
  background-color: #fff;
    box-shadow: 0px 0px 8px #e89246;
    color: #e89246;
}
.LoginModal2Div4 button:hover {
  background-color: #464142;
  box-shadow: 0px 0px 8px black;
  color: white;
}

.closebtnforSignUp {
  text-align: right;
  width: 99%;
  color: black;
  z-index: 999;
  background-color: transparent;
  border-radius: 100%;
  /* padding-right: 2%; */
}

.closebtnforSignUp button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
  font-weight: 800;
  transition: all 0.2s;
}

/* .closebtnforSignUp button:hover {
      color: rgb(247, 51, 51);
      text-shadow: 0px 0px 3px red;
  } */
.signUpBtn {
  /* font-size: 1rem!important; */
  color: #a2a2a2;
  /* margin-right: 2.5vw !important; */
  /* margin-right: 7vh !important; */
}
.signUpBtn:hover {
  color: rgb(247, 51, 51);
  text-shadow: 0px 0px 3px red;

  /* background-color: #464142; */
  /* box-shadow: 0px 0px 8px black; */
}
/* .closebtnforLoginUp {
      text-align: right;
      width: 95%;
      color: black;
      background-color: white;
      border-radius: 100%;
      padding-right: 1%;
  } */

/* / For Login Cross Button  /      */
.closebtnforLoginUp {
  text-align: right;
  width: 95%;
  color: black;
  background-color: white;
  border-radius: 100%;
  /* / / padding-right: 0.8%; /  /         */
}

@media screen and (min-width: 1297px) {
  .closebtnforLoginUp {
    padding-right: 0.8%;
  }
}
@media screen and (max-width: 1297px) and (min-width: 1050px) {
  .closebtnforLoginUp {
    /* / padding-right: 8px;  */
    padding-right: 2%;
  }
}
@media screen and (max-width: 1050px) and (min-width: 700px) {
  .closebtnforLoginUp {
    /* / padding-right: 10px; / */
    padding-right: 3%;
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .closebtnforLoginUp {
    /* / padding-right: 13px; / */
    padding-right: 3.5%;
  }
}
@media screen and (max-width: 500px) and (min-width: 400px) {
  .closebtnforLoginUp {
    /* / padding-right: 15px;      / */
    padding-right: 5%;
  }
}
@media screen and (max-width: 400px) and (min-width: 100px) {
  .closebtnforLoginUp {
    /* / padding-right: 17px;    / */
    padding-right: 7%;
  }
}
/* / For Login Cross Button  /     */
.closebtnforsignUp {
  text-align: right;
  width: 98%;
  color: black;
  background-color: transparent;
  border-radius: 100%;
  padding-right: 2%;
}

.profileNavDropDown {
  border: 1px solid transparent;
  box-shadow: 0px 0px 4px #807e7e;
  position: absolute;
  z-index: 99 !important;
  background-color: #2c2c2c;
  color: white;
  right: 4vh;
  margin-top: 2.3vh;
}

.loginModalmainDiv {
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-image: url("https://i.imgur.com/tfzIgAj.jpg"); */
  /* background-image: url(assets/images/ModalBackImg.jpg) */
  
  background-size: cover;
  background-position-y:center;
  background-repeat: no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.213);
  border-radius: 10px
}

.loginModalDiv1portion {
  width: 40%;
  background-color: #fff;
  margin: 3rem;
  border-radius: 4px;
   box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3); 
}
.registerFormModal{
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.loginModalDiv1portion input {
  width: 75%;
  margin: 1.4vh;
  padding: 1.3vh;
  background-color: #fff;
  color: #414042;  
  border: 1px solid transparent;
  font-size: 1.2vw;
  letter-spacing: 0.5px;
  border-radius: 3px;
  font-weight: 600;
}

/* Select Tag */
.loginModalDiv1portion select{
  width: 75%;
  margin: 1.4vh;
  padding: 1.3vh;
  background-color: #fff;
  color: #414042;  
  border: 1px solid transparent;
  font-size: 1vw;
  letter-spacing: 0.5px;
  border-radius: 3px;
  /* font-weight: 600; */
}



.loginModalDiv1portion button {
  padding: 7px;
  background-color: #e89246;
  width: 75%;
  border-radius: 7px;
  word-wrap: break-word;
  font-weight: 700;
  letter-spacing: 0.21px;
  border: none;
  font-size: 1.3vw;
  font-weight: 400;
  transition: all 0.5s;
  margin-top: 2vh;
  color: #fff;
}

.loginModalforgotpasswordSpan {
  font-size: 1.8vh;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  letter-spacing: 0.3px;
  word-wrap: break-word;
  margin-top: 4vh;
}

.loginModalforgotpasswordSpan p {
  cursor: pointer;
  transition: all 0.5s;
}

.loginModalforgotpasswordSpan p:hover {
  color: blue;
  transform: scale(1.2);
}

.loginLabel {
  padding-top: 4vh;
  /* font-size: 4vh; */
  font-size: 2vw;
  font-weight: 600;
  word-wrap: break-word;
  font-family: sans-serif;
  color: #414042;
}

.loginModalDiv2portion {
  width: 50%;
  border-radius: 4px;
  margin-right: 9px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

}
.loginPopContainer img{
  height: 5vh;
  width: 5vh !important;
  top: 0;
  left: 0;
}
.loginPopContainer div{
  background-color: #ffffffc9;
  width: 35vh;
  height: 20vh;
  /* border: 2px solid rgba(149, 149, 149, 0.507); */
  border-radius: 20px;

}
.loginPopContainer .imgDiv{
  background-color: transparent !important;
  border: 0rem !important;
  width: 35vh;
  height: 7vh;
}
.imgDiv img{
  width: 7vh !important;
  height: 7vh !important;
  margin-top: 4vh;
  margin-left: -1vh;  
}
.loginPopContainer span{
  color: #e89246;
}
.loginPopContainer h4{
  font-weight: 300 !important; 
}
.loginPopContainer h5{
  font-weight: 300 !important;
}
.loginPopContainer h3{
  font-weight: 300 !important;
}
.loginPopContainer .imgDoubleQuote{
  position: absolute;
  top: 42vh;
  left: 48vh;
  width: 7vh !important;
  height: 7vh !important;
}

.loginModalDiv2portion img {
  border-radius: 6px;
  width: 100%;
}

.loginModalSignUp {
  font-size: 1.4vh;
  color: #414042;  
  /* margin-top: 7vh; */
  word-wrap: break-word;
  margin-bottom: 7vh;
}

.loginModalSignUp u {
  cursor: pointer;
  transition: all 0.4s;
}

.loginModalSignUp u:hover {
  color: #e89246;
  transform: scale(1.1);
}

.projectTabmainDiv {
  width: 100%;
  min-height: 50vh;
  background: #ffffffe8;
  text-align: center;
}

.signUpMainDiv1 {
  display: flex;
  justify-content: center;
}

.signupDivPortion1 {
  width: 50%;
  background-color: white;
  box-shadow: 0 0 7px white;
  margin: 1px;
  color: #2c2c2c;
  min-height: 70vh;
  border: 1px solid rgb(230, 230, 230);
}

.signupDivPortion2 {
  box-shadow: 0 0 7px #b8b8b8;
  width: 50%;
  border: 1px solid #d5d5d5;
  min-height: 60vh;
  margin: 1px;
  background-color: #2c2c2c;
  color: white;
}

.signupDivPortion2 input::placeholder {
  color: rgb(210, 210, 210);
}

.signupDivPortion1 input::placeholder {
  color: rgb(121, 121, 121);
}

.signuplabel {
  font-size: 1.7rem;
  font-weight: 700;
  padding-top: 2vh;
  padding-bottom: 1vh;
  z-index: 99;
  position: sticky;
  top: 0.01vh;
  margin-bottom: 1vh;
  box-shadow: 0 1px 7px #2c2c2c;
  border: 1px solid transparent;
  color: white;
  background-color: #ffda25;
}
.signuplabel2 {
  font-size: 1.7rem;
  font-weight: 700;
  padding-top: 2vh;
  padding-bottom: 2vh;
  z-index: 99;
  position: sticky;
  top: 0.01vh;
  margin-bottom: 1vh;
  box-shadow: 0 1px 7px #b7b7b7;

  border: 1px solid rgb(227, 227, 227);
  color: black;
}

.signupgenlabel {
  font-weight: 500;
  margin-top: 2vh;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.signupInputsdiv1 input {
  padding: 4px;
  background-color: transparent;
  border-bottom: 0.3px solid rgb(115, 115, 115);
  border-radius: 2px;
  color: black;
  font-size: 1vw;
  width: 80%;
  transition: all 0.4s;
  margin: 2vh;
}

.signupInputsdiv2 input {
  padding: 4px;
  background-color: transparent;
  border-bottom: 0.3px solid rgb(220, 220, 220);
  border-radius: 2px;
  color: white;
  font-size: 1vw;
  width: 80%;
  margin: 2vh;
  transition: all 0.4s;
}

.row {
  margin-top: 15px;
}

.signupInputsdiv1 textarea {
  padding: 4px;
  background-color: transparent;
  border-bottom: 1px solid rgb(115, 115, 115);
  border-radius: 2px;
  color: black;
  font-size: 1vw;
  width: 80%;
  margin: 2vh;
  min-height: 2rem !important;
  transition: all 0.4s;
}

.signupDivPortion1 input:hover,
.signupDivPortion1 textarea:hover {
  box-shadow: 0px 0px 8px #b8b8b8;
  transform: scale(1.04);
}

.signupDivPortion1 input:focus,
textarea:focus {
  box-shadow: 0px 0px 8px #b8b8b8;
}

.signupDivPortion1 textarea:focus {
  box-shadow: 0px 0px 8px #b8b8b8;
}

.signupDivPortion2 input:hover,
textarea:hover {
  box-shadow: 0px 0px 8px #9e9e9e;
  transform: scale(1.04);
}

.signupDivPortion2 input:focus,
textarea:focus {
  box-shadow: 0px 0px 8px #9e9e9e;
  transform: scale(1.04);
}

.singupbtn {
  margin-top: 12vh;
  background-color: #ffda25;
  border: 1px solid transparent;
  padding: 3px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  margin-right: 3vh;
  margin-bottom: 2vh;
  font-weight: 500;
  border-radius: 70px;
  transition: all 0.2s;
  font-family: Arial, Helvetica, sans-serif;
}

.singupbtn:hover {
  background-color: white;
  box-shadow: 0 0 4px #ffda25;
  color: #2c2c2c;
  transform: scale(1.1);
}

.alreadySignin {
  font-size: 16px;
  margin: 0.5vh;
  cursor: pointer;
  transition: all 0.3s;
}

.projectMainDiv {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #2c2c2c;
  overflow: scroll;
  max-height: 100vh;
  /* position: fixed; */
  /* top: 0; */
  /* margin-top: 5rem; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  z-index: 999 !important;
}

.alreadySignin:hover {
  opacity: 1;
}

.projectBackgroundDiv {
  /* background-image: url('https://i.pinimg.com/originals/de/44/d5/de44d5172d2b0e3cfeec35a15f9359d8.jpg'); */
  background-color: #464142;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 10vh;
  bottom: 0;
  left: 0;
  right: 0;
  /* position: fixed;     */
  z-index: 3;
  display: flex;
  justify-content: center;
}

#carouselExampleControls {
  z-index: 99 !important;
}

.projectDiv1 {
  margin: 12vh 10vh 0vh 10vh;
  width: 100%;
  min-height: 24vh;
  background-color: #818181;
  /* background-image: url('https://reactheme.com/products/html/archtek/assets/images/portfolio/details/2.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  transition: all 0.2s;
}

.projectDiv1 {
  --c: no-repeat linear-gradient(#302e2e 0 0);
  background: var(--c) calc(-101% + var(--p, 0%)) 100% / 50.1% var(--p, 8em),
    var(--c) calc(201% - var(--p, 0%)) 0 / 50.1% var(--p, 2rem);
  transition: 0.3s var(--t, 0s),
    background-position 0.3s calc(0.3s - var(--t, 0s));
}

.projectDiv1:hover {
  /* text-shadow: 0 0 20px rgb(248, 245, 245); */
  box-shadow: 0 0 30px #2c2c2c;
  color: black;
  --p: 101%;
  --t: 0.3s;
}

.projectDiv1 h1 {
  color: white;
  margin-top: 4vh;
  padding: 4vh;
  transition: all 0.3s;
}

.projectDiv2 {
  display: flex;
  justify-content: center;
  /* margin: 10vh 3vh; */
  margin: 2vh 3vh;
}

.displayflex {
  display: flex;
  justify-content: center;
}

.projectdiv3,
.projectdiv3 h3 {
  /* margin: 5vh; */
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  letter-spacing: 1px;
  overflow: hidden;
}

.projectdiv3 img {
  max-height: 45rem;
  width: 100%;
  margin-bottom: 4vh;
  transition: 0.4s;
  overflow: hidden;
}

.projectdiv3 img:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.projectdiv3 p {
  /* margin: 5vh; */
  color: white;
  margin-bottom: 5vh;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  letter-spacing: 1px;
  font-size: 15px;
}

.projectdiv4 {
  /* margin: 4vh; */
  overflow: hidden;
  color: white;
}

.projectdiv4 h3 {
  color: white;
}

.labelofProject {
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 4vh;
  padding: 2vh;
  border: none;
  font-size: 22px;
  color: white;
  font-weight: 600;
}

.projectCommonDiv {
  margin: 1vh;
}

.projectCommonDiv td {
  padding: 1vh;
  font-size: 0.7rem;
}

.projectdiv5 {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid none;
  color: black;
  transition: all 0.4;
}
.projectdiv5:hover {
  opacity: 0.9;
  box-shadow: 0 0 10px black;
}

.projectimage2 {
  overflow: hidden;
  transition: 0.5s ease;
}

.projectimage2:hover {
  /* transform: scale(1.09); */
  opacity: 0.7;
  box-shadow: 0 0 10px #2c2c2c;
  text-shadow: 0 0 15px wheat;
}

.card-text {
  color: black;
}

.hover {
  --b: 0.1em;
  /* the thickness of the line */
  --c: #1095c1;
  /* the color */

  color: #0000;
  padding-block: var(--b);
  background: linear-gradient(var(--c) 50%, #000 0) 0%
      calc(100% - var(--_p, 0%)) / 100% 200%,
    linear-gradient(var(--c) 0 0) 0% var(--_p, 0%) / var(--_p, 0%) var(--b)
      no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.3s var(--_s, 0s) linear,
    background-size 0.3s calc(0.3s - var(--_s, 0s));
}

.hover:hover {
  --_p: 100%;
  --_s: 0.3s;
}

.hover-1 {
  color: black;
  cursor: pointer;
  background: linear-gradient(#c4c2c2 0 0) var(--p, 0) / var(--p, 0) no-repeat;
  transition: 0.4s, background-position 0s;
}

.hover-1:hover {
  --p: 100%;
  color: #fff !important;
  transform: scale(1.1);
  border: none;
  box-shadow: 1px 1px 100px rgb(241, 232, 232);
}

.hover-2 {
  background: linear-gradient(#1095c1 0 0) no-repeat calc(200% - var(--p, 0%))
    100% / 200% var(--p, 0.08em);
  transition: 0.3s var(--t, 0s),
    background-position 0.3s calc(0.3s - var(--t, 0s));
}

.hover-2:hover {
  --p: 100%;
  --t: 0.3s;
  color: #fff;
}

/* Text Hover Effect 1 OPEN  */
.text-hover1 {
  color: #0000;
  background: radial-gradient(
      circle,
      rgba(212, 2, 249, 1) 28%,
      rgba(250, 251, 61, 1) 53%,
      rgba(0, 212, 255, 1) 100%
    )
    var(--c, 0%) / 200% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  transition: 0.5s;
}

.text-hover1:hover {
  --c: 100%;
}

/* Text Hover Effect 1 CLOSE  */

/* Text Hover Effect 2 OPEN  */
.text-hover2 {
  line-height: 1.1em;
  padding: calc(2.1 * 0.15em);
  background: linear-gradient(to top, red, red) left 0 bottom 0em / calc
      (2 * 0.01em) 0.05em repeat-x,
    linear-gradient(to top, #1095c1, #1095c1) right 0em bottom 0% /
      calc(2 * 0.03em) 0.05em repeat-y,
    linear-gradient(to top, red, red) left 0 top 0.15em / calc (2 * 0.03em)
      0.15em repeat-y,
    linear-gradient(to top, #1095c1, #1095c1) left 0.15em top 0% /
      calc(2 * 0.15em) 0.05em repeat-x;

  -webkit-mask: linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0) 0 / var(--_p, 0%) padding-box no-repeat;
  transition: 0.5s;
}

.text-hover2:hover {
  --_p: 100%;
  color: #1095c1;
}

/* Text Hover Effect 2 CLOSE  */

/* Text Hover 3 Effect OPEN  */
.text-hover3 {
  line-height: 1.1em;
  padding: calc(2.1 * 0.15em);
  background: linear-gradient(to top, red, red) left 0 var(--a, bottom) 0em /
      calc (2 * 0.01em) 0.05em repeat-x,
    linear-gradient(to top, #1095c1, #1095c1) var(--b, right) 0em bottom 0% /
      calc(2 * 0.03em) 0.05em repeat-y,
    linear-gradient(to top, red, red) var(--c, left) 0 top 0.15em / calc
      (2 * 0.03em) 0.15em repeat-y,
    linear-gradient(to top, #1095c1, #1095c1) left 0.15em var(--d, top) 0% /
      calc(2 * 0.15em) 0.05em repeat-x;

  -webkit-mask: linear-gradient(#000 0 0) content-box,
    linear-gradient(#000 0 0) 0 / var(--_p, 100%) padding-box no-repeat;
  transition: 0.5s;
}

.text-hover3:hover {
  --a: top;
  --b: left;
  --c: right;
  --d: bottom;
  --_p: 100%;
  color: #1095c1;
}

.text-hover4 {
  box-shadow: inset 0 0 0 0 #ffda25;
  color: white;
  /* margin: 0 -.25rem;
      padding: 0 .25rem;  */
  transition: color 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
}

.text-hover4:hover {
  box-shadow: inset 1000px 0 0 0 rgba(255, 218, 37, 0.1);
  color: white;
}

.text-hover5 {
  background-image: linear-gradient(to right, #54b3d6, #54b3d6 50%, white 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.text-hover5:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.text-hover5:hover {
  background-position: 0;
}

.text-hover5:hover::before {
  width: 100%;
}

.text-hover6 {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.text-hover6::before,
.text-hover6::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

.text-hover6::before {
  background-color: #ffda25;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.text-hover6::after {
  content: attr(data-replace);
  text-shadow: 0 0 7px black;
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  color: #ffda25;
}

.text-hover6:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.text-hover6:hover::after {
  transform: translate3d(0, 0, 0);
}

.text-hover6 span {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.text-hover6:hover span {
  transform: translate3d(-200%, 0, 0);
}

/* Presentational Styles */
.text-hover6 {
  text-decoration: none;
  color: white;
  letter-spacing: 2px;
  font-weight: 700;
  vertical-align: top;
}

.text-hover7 {
  box-shadow: inset 0 0 0 0 #ffda25;
  color: white;
  /* margin: 0 -.25rem;
      padding: 0 .25rem;  */
  transition: color 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
}

.text-hover7:hover {
  box-shadow: inset 1000px 0 0 0 rgba(255, 218, 36, 0.3);
  color: white;
}
.text-hover8 {
  font-weight: 900;
  transition: all 0.3s;
}
.text-hover8:hover {
  color: red !important;
  text-shadow: 0 0 3px #ffda25;
}
/* Text Hover 3 Effect CLOSE  */

/* Projec cards Button hover OPEN */
.button-hover4 {
  background: #26a69a;
  margin: 20px auto;
  padding-top: 1.3vh;
  /* width: 200px; */
  height: 45px;
  overflow: hidden;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.btnTwo {
  position: relative;
  width: 200px;
  height: 200px;
  margin-top: -103px;
  color: black;
  text-shadow: 3px 3px 7px black;
  /* padding-top: 2px; */
  background: #ffda25;
  left: -250px;
  transition: 0.3s;
}

.btnText {
  color: white;
  transition: 0.3s;
}

.btnText2 {
  font-size: 2rem;
  margin-top: 61px;
  margin-right: -130px;
  color: #fff;
}

.button-hover4:hover .btnTwo {
  left: -130px;
}

.button-hover4:hover .btnText {
  margin-left: 65px;
}

.button-hover4:active {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
}

/* Projec cards Button hover CLOSE */

/* Hover 4 in Project Modal 9 images tag  OPEN */
.projectModalHoverul {
  width: 70vmin;
  height: 70vmin;
  position: relative;
}

.projectModalHoverli {
  --h: calc(100% / 3);
  --w: calc(1.15470053838 * var(--h));
  --a: calc(0.28867513459 * var(--h));
  --d: calc(0.57735026919 * var(--h));
  height: var(--h);
  width: var(--w);
  transition: all 0.3s;
  position: absolute;
}

.projectModalHoverimg {
  display: block;
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  --aw: 24.99999999999%;
  clip-path: polygon(
    var(--aw) 0,
    0 50%,
    var(--aw) 100%,
    calc(100% - var(--aw)) 100%,
    100% 50%,
    calc(100% - var(--aw)) 0
  );
}

.projectModalHoverimg:hover {
  transform: scale(1.07);
}

.projectModalHoverli:nth-of-type(1) {
  left: calc(50% - var(--a) - var(--d) * 0.5);
  top: 0;
}

.projectModalHoverli:nth-of-type(2) {
  left: calc(50% - var(--a) * 2 - var(--d) * 1.5);
  top: calc(var(--h) * 0.5);
}

.projectModalHoverli:nth-of-type(3) {
  left: calc(50% - var(--a) * 2 - var(--d) * 1.5);
  top: calc(var(--h) * 1.5);
}

.projectModalHoverli:nth-of-type(4) {
  left: calc(50% - var(--a) - var(--d) * 0.5);
  top: calc(var(--h) * 2);
}

.projectModalHoverli:nth-of-type(5) {
  left: calc(50% + var(--d) * 0.5);
  top: calc(var(--h) * 0.5);
}

.projectModalHoverli:nth-of-type(6) {
  left: calc(50% + var(--d) * 0.5);
  top: calc(var(--h) * 1.5);
}

.projectModalHoverul {
  filter: drop-shadow(0 0 10px hsla(0, 0%, 0%, 0.3));
}

.projectModalHoverli:hover {
  filter: drop-shadow(0 0 10px powderblue);
  z-index: 10;
}

.projectModalHoverul {
  animation: r 4s forwards;
}

@keyframes r {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0);
  }
}

.projectModalHoverli {
  animation: ir 4s forwards;
}

@keyframes ir {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0);
  }
}

/* Hover 4 in Project Modal 9 images tag  CLOSE */

/* 3D Animation Image Hover  */

.Project3Dimage {
  perspective: 3000px;
  width: 50%;
  /* position: absolute; */
  left: 15%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
}

.Project3Dimage img {
  transform: rotateX(70deg) rotateZ(-60deg) translate3d(-120px, 0px, 70px);
  box-shadow: -80px 60px 15px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.4s;
  transform-style: preserve-3d;
}

.Project3Dimage:hover img {
  transform: rotateX(0deg) rotateZ(0deg) translate3d(0px, 0px, 0px);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.transparent {
  background-color: transparent;
}

.border-none {
  border: none !important;
}

.colorwhite {
  color: white;
}

/* PROJECT CENTER DIV  */
.projectCenterDiv {
  margin: 3vh 2vh;
  /* display:contents; */
  justify-content: center;
}

.projectCenterDiv2 {
}

.absolute {
  position: absolute;
}

#background {
  display: inline-block;
  background: #6087b8;
}

#background a {
  display: block;
  padding: 10px 20px;
  color: #ffd583;
  position: relative;
  z-index: 2;
}

#background div {
  position: absolute;
  display: block;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffd583;
  z-index: 1;
  overflow: hidden;
  transition: 0.8s all ease;
}

#background:hover div {
  width: 100%;
}

#background span {
  position: absolute;
  color: #6087b8;
  width: 123px;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  height: 100%;
  padding: 10px 0;
}

/* BUTTON ANIMATIONS */

.btnHover {
  position: relative;
  padding: 1.4rem 4.2rem;
  /* padding-right: 3.1rem;  */
  font-size: 1.4rem;
  color: var(--inv);
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
}

.btnHover:before,
.btnHover:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.btnHover:hover {
  color: var(--def);
  transition-delay: 0.5s;
}

.btnHover:hover:before {
  transition-delay: 0s;
}

.btnHover:hover:after {
  background: var(--inv);
  transition-delay: 0.35s;
}

/* From Top */

.from-top:before,
.from-top:after {
  left: 0;
  height: 0;
  width: 100%;
}

.from-top:before {
  bottom: 0;
  border: 1px solid var(--inv);
  border-top: 0;
  border-bottom: 0;
}

.from-top:after {
  top: 0;
  height: 0;
}

.from-top:hover:before,
.from-top:hover:after {
  height: 100%;
}

/* From Left */

.from-left:before,
.from-left:after {
  top: 0;
  width: 0;
  height: 100%;
}

.from-left:before {
  right: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-left:after {
  left: 0;
}

.from-left:hover:before,
.from-left:hover:after {
  width: 100%;
}

/* From Right */

.from-right:before,
.from-right:after {
  top: 0;
  width: 0;
  height: 100%;
}

.from-right:before {
  left: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-right:after {
  right: 0;
}

.from-right:hover:before,
.from-right:hover:after {
  width: 100%;
}

/* From center */

.from-center1:before {
  top: 0;
  left: 50%;
  height: 100%;
  width: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-center1:after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: var(--inv);
}

.from-center1:hover:before {
  left: 0;
  width: 100%;
}

.from-center1:hover:after {
  top: 0;
  height: 100%;
}

/* From Bottom */

.from-bottom:before,
.from-bottom:after {
  left: 0;
  height: 0;
  width: 100%;
}

.from-bottom:before {
  top: 0;
  border: 1px solid var(--inv);
  border-top: 0;
  border-bottom: 0;
}

.from-bottom:after {
  bottom: 0;
  height: 0;
}

.from-bottom:hover:before,
.from-bottom:hover:after {
  height: 100%;
}

/* ~~~~~~~~~~~~ GLOBAL SETTINGS ~~~~~~~~~~~~ */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  --def: black;
  --inv: #ffda25;
  /* --inv:#ffffff; */
}

.relative {
  position: relative !important;
}

.ProjectImagesCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 360px; */
  max-width: 360px;
  width: 60vw;
  height: auto;
  background: rgba(234, 231, 231, 0.8);
}

.ProjectImagesCard .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ProjectImagesCard .image img {
  width: 100%;
  transition: 0.5s;
}

.ProjectImagesCard:hover .image img {
  opacity: 0.5;
  transform: translateX(30%);
  /*100%*/
}

.ProjectImagesCard .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  /*100%*/
  height: 100%;
  background-color: rgba(26, 25, 25, 0.3);
  border: 1px solid transparent;
  /* background: rgb(0, 0, 0); */
  transition: 0.5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}

.ProjectImagesCard:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}

.ProjectImagesCard .details .center {
  padding: 20px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ProjectImagesCard .details .center h1 {
  margin: 0;
  padding: 0;
  color: #ff3636;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.ProjectImagesCard .details .center h1 span {
  font-size: 14px;
  color: #262626;
}

.ProjectImagesCard .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #262626;
}

.ProjectImagesCard .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}

.ProjectImagesCard .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}

.ProjectImagesCard .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: 0.5s;
}

.ProjectImagesCard .details .center ul li a:hover {
  background: #ff3636;
}

.skew-1-1 {
  transition: all 0.6 !important;
}

.skew-1-1:hover {
  transform: scale(1.06);
}

.projectDivhoverdivs1,
.projectDivhoverdivs2,
.projectDivhoverdivs3,
.projectDivhoverdivs4 {
  /* background-color: rgba(232, 223, 223, 0.1);  */
  /* background-color: white; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid transparent;
  overflow: auto;
  z-index: 9 !important;
  border-radius: 19px;
  animation: showInSlow 0.4s ease-in-out;
  transition: all 0.7s !important;
}

.projectDivhoverdivs1 {
  background-image: url("https://content.jdmagicbox.com/comp/pune/b8/020pxx20.xx20.130917092318.n2b8/catalogue/vastumantrah-hadapsar-pune-interior-designers-94143zsey3.jpg");
}

.projectDivhoverdivs2 {
  background: url("https://media.istockphoto.com/id/1147086081/photo/modern-concept-interior-design-of-black-and-grey-living-room-3d-render.jpg?b=1&s=170667a&w=0&k=20&c=pM3NxWkA3KLZLMgZdl0aws-5n0cZHS8l480QF-OAQAQ=");
}

.projectDivhoverdivs3 {
  background: url("https://t4.ftcdn.net/jpg/01/85/57/95/360_F_185579520_dpVgkfiO79Yn94vRrySzQ6zPFw3rpw3I.jpg");
}

.projectDivhoverdivs4 {
  background: url("https://www.decorpot.com/images/breadcumb/Living-room-Mob.jpg");
}

.hoverME1,
.hoverME2,
.hoverME3,
.hoverME4 {
  cursor: pointer !important;
}
.hoverME2 {
  background-color: #ffda25;
}
/* .hoverME1:hover+.projectDivhoverdivs1  {
      display: block !important;
  
  }  */

/* .hoverME2:hover+ */
.projectDivhoverdivs2 {
  display: block !important;
}
/* 
  .hoverME3:hover+.projectDivhoverdivs3 {
      display: block !important;
  } */

/* .hoverME4:hover+.projectDivhoverdivs4 {
      display: block !important;
  } */
.showInSlowanim {
  animation: showInSlow 0.4s ease-in-out;
  transition: all 0.7s !important;
}
@keyframes showInSlow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.SlidInanimation {
  animation: slideUp 0.6s ease-in;
}

.SlidLeftanimation {
  animation: slideIn 0.4s ease-in;
}

.opacity1animation {
  animation: opacity1 0.6s ease-in;
}
.slideleft {
  animation: Slideleft 0.6s ease-in;
}
.slideright {
  animation: Slideright 0.6s ease-in;
}

.fakeborder-csstricks {
  background-image: repeating-linear-gradient(
      57deg,
      #333333,
      #333333 15.9px,
      transparent 30px,
      transparent 45.9px,
      #333333 60px
    ),
    repeating-linear-gradient(
      147deg,
      #333333,
      #333333 15.9px,
      transparent 30px,
      transparent 45.9px,
      #333333 60px
    ),
    repeating-linear-gradient(
      237deg,
      #333333,
      #333333 15.9px,
      transparent 30px,
      transparent 45.9px,
      #333333 60px
    ),
    repeating-linear-gradient(
      327deg,
      #333333,
      #333333 15.9px,
      transparent 30px,
      transparent 45.9px,
      #333333 60px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes opacity1 {
  0% {
    opacity: 0;
    /* transform: translateY(300px) ; */
  }

  100% {
    opacity: 1;
    /* transform: translateY(0px); */
  }
}

@keyframes Slideleft {
  0% {
    opacity: 0;
    transform: translateX(300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes Slideright {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.imagesProject {
  /* overflow: hidden; */
  border-radius: 3px;
  background-color: transparent;
  border: none;
  padding: 10px 10px 10px;
  box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
  -moz-box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
  -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
}

.imagesProject:hover img {
  z-index: 99;
  border-radius: 12px;
  filter: contrast();
  transform: scale(1.14);
  cursor: pointer;
}

.timeline-1 {
  border-left: 3px solid #b565a7;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(177, 99, 163, 0.09);
  margin: 0 auto;
  position: relative;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 60%;
}

@media (max-width: 767px) {
  .timeline-1 {
    max-width: 98%;
    padding: 25px;
  }
}

.timeline-1 .event {
  border-bottom: 1px dashed #000;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline-1 .event {
    padding-top: 30px;
  }
}

.timeline-1 .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-1 .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline-1 .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline-1 .event:after {
  -webkit-box-shadow: 0 0 0 3px #b565a7;
  box-shadow: 0 0 0 3px #b565a7;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline-1 .event:after {
    left: -31.8px;
  }
}

.loginModalDiv1portion input {
  width: 75%;
  margin: 1.4vh;
  padding: 1.3vh;
  background-color: #fff;
  border: 2px solid #c1bac8;
  color: #414042;
  font-size: 0.9vw;
  letter-spacing: 0.5px;
  font-weight: 100 !important;
}

.loginModalDiv1portion Select {
  width: 75%;
  margin: 1.4vh;
  padding: 1.3vh;
  background-color: #fff;
  border: 2px solid #c1bac8;
  color: #414042;
  font-size: 0.9vw;
  letter-spacing: 0.5px;
  font-weight: 100 !important;
}
.loginModalDiv1portion input::placeholder {
 color: #c1bac8;
}

/* Select Tag */
.loginModalDiv1portion Select::placeholder { 
  color: #c1bac8;  
 }

@media only screen and (max-width: 350px) and (min-width: 250px) {
  .box-custom-1-list {
    display: flex;
    margin-bottom: -23px;
    margin-left: -54px;
  }
  .owl-stage {
    transition: all 0.25s ease 0s;
    width: 3516px;
    transform: translate3d(-1758px, 0px, 0px);
    margin-bottom: 59px;
  }
  .button {
    min-width: 205px;
  }
  .summary-list .list {
    margin-left: -33%;
  }
  .modalContainer1 {
    padding-left: 5vh;
    padding-right: 4vh;
  }
}
.justifyCenter {
  display: flex;
  justify-content: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.centerss {
  position: absolute;
  left: 48%;
  top: 30vh;
  z-index: 999 !important;
}

.disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.uploadfileModal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 25vh;
  position: fixed;
  overflow: hidden;
  background-color: rgba(240, 238, 238, 0.6);
  z-index: 999 !important;
}
.uploadfileDiv {
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.container111111 {
  background-color: white;
  border: white;
}

.registerin {
}
.registerin input {
}

.SignModalDiv2portion {
  width: 50%;
  /* margin-right: 9px; */

  /* background-image: url("https://img.lovepik.com/background/20211030/medium/lovepik-interior-design-mobile-wallpaper-background-image_400482219.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.SignModalDiv2portion img {
  border-radius: 6px;
  width: 100%;
}
@media only screen and (max-width: 500px) and (min-width: 412px) {
  .detailTable tbody tr td {
    padding: 1rem 0.4rem !important;
    font-size: 11px;
  }
  .detailTable tbody tr th {
    padding: 1rem 0.4rem !important;
    font-size: 11px;
  }
}
@media only screen and (max-width: 412px) and (min-width: 306px) {
  .detailTable tbody tr td {
    padding: 1rem 0.4rem !important;
    font-size: 9px;
  }
  .detailTable tbody tr th {
    padding: 1rem 0.4rem !important;
    font-size: 9px;
  }
}

@media only screen and (max-width: 305px) and (min-width: 279px) {
  .detailTable tbody tr td {
    padding: 1rem 0.4rem !important;
    font-size: 7.5px;
  }
  .detailTable tbody tr th {
    padding: 1rem 0.4rem !important;
    font-size: 7.5px;
  }
}

@media only screen and (max-width: 278px) and (min-width: 200px) {
  .detailTable tbody tr td {
    padding: 1rem 0.4rem !important;
    font-size: 6px;
  }
  .detailTable tbody tr th {
    padding: 1rem 0.4rem !important;
    font-size: 6px;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 990px) {
  .detailTable tbody tr td {
    padding: 1rem 0.4rem !important;
    font-size: 13px;
  }
  .detailTable tbody tr th {
    padding: 1rem 0.4rem !important;
    font-size: 13px;
  }
}
.logo {
  width: fit-content;
}
.spinnerDiv {
  display: inherit;
  position: relative;
  font-size: 0px;
  top: 20px;
  left: 20px;
  width: 60px;
  margin: auto;
  height: 60px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.getaQuoteModalSignup{
  width: 75% !important;
  margin-left: 11% !important; 
  margin-right: 11% !important;
}


@media only screen and (max-width: 500px) and (min-width: 100px) {  
 .loginModalDiv2portion{
      display: none !important;
 }
 .SignModalDiv2portion{
  display: none !important;
 }
 .loginModalDiv1portion{
  width: 90% !important;
 }
 .loginLabel{
  font-size: 7vw !important;
 }
 .loginModalmainDiv{
  background-image: none !important;
  border: 0 !important;
 }
 .LoginModalDiv4{
  margin-top: 15vh !important;
  width: 90% !important;
  height: 100vh !important;  
 }
 .loginModalSignUp{
  font-size: 3.5vw !important;
 }
 .loginModalDiv1portion input{
  font-size: 3.5vw !important;
 }
 /* Select Tag */
 .loginModalDiv1portion select{
  font-size: 3.3vw !important;
 }
 .getaQuoteModalDiv4{
  width: 100% !important;
  margin-left: 0% !important;
  margin-right: 7% !important;
  margin-top: 12vh !important;  
 }
 .ReactModal__Content{
  width: 100% !important;
 }

.closebtnforLoginUp .getCloseBtnNav{
  color: black;
}
.getQuoteLabel{
  font-size: 6.3vw !important;
}
}





