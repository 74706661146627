.about-boxes {
    /* background: url('https://www.cloudcertitude.com/assets/img/about-boxes-bg.jpg') center top no-repeat fixed; */
    background-size: cover;
    padding: 2rem 0 30px 0;
    position: relative;
    overflow: hidden;
    background-color: #EBFBFB;
}
.card-img-top{
    height: 240px !important;
    border-radius: 0 !important;
}
.about-boxes .container, .about-boxes .container-fluid {
    position: relative;
    z-index: 10;
}
.clientJourneyHead{
        text-align: center;
        color: #222222;
        font-size: 36px;
        font-weight: bold;
        /* margin-top: 9px; */
        margin-bottom: 20px;
}
@media (max-width: 768px){  
    .clientJourneyHead {
        font-size: 22.08px;
        padding-bottom: 10px;
    }
}
.about-boxes::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background: rgba(255, 255, 255, 0.92); */
    z-index: 9;
}

.about-boxes .card {
    border-radius: 3px;
    border: 0;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); */
    box-shadow: none !important;
    margin-bottom: 30px;
    background-color: #EBFBFB !important;
}

.about-boxes .card-icon {
    text-align: center;
    margin-top: -32px;
}

.about-boxes .card-icon i {
    font-size: 32px;
    color: #fff;
    width: 64px;
    height: 64px;
    padding-top: 5px;
    text-align: center;
    background-color: #ff4a17;
    border-radius: 4px;
    text-align: center;
    border: 4px solid #fff;
    transition: 0.3s;
    display: inline-block;
}

.about-boxes .card-body {
    padding-top: 12px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
}

.about-boxes .card-title {
    font-weight: 700;
    /* text-align: center; */
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    display: flex;
}
.NumberSpan{
    padding: 10px;
}
.HeadingSpan{
    padding: 0px !important;
}
.about-boxes .card-title .NumberSpan{
    color: #e89246;
    font-size: 24px;
    font-weight: 700; 
}
.about-boxes .card-title .HeadingSpan{
    color: #012970;
    font-size: 18px; 
    font-weight: 700;
}


.about-boxes .card-text {
    color: #15222b;
    font-size: 16px;
}
.clientJourneyBtn{
    padding:10px;
    border-radius: 10px;
    border: 1px solid #e89246;
    color: #e89246;
    background-color: #fff;
}
.clientJourneyBtn:hover{
    background: #e89246;
    color: #fff;
}
