/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap'); */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.containerContactUs{
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.bodyDiv {
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(.Pi/assets/images/bg-price-1920x1128.png); */
  /* background-image: url(../public/assets/images/bg-price-1920x1128.png); */
  /* background-image: url(https://super.homelane.com/updatedwebp/bfc/MSD_BFC_desktop.webp?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940); */
  background-size: cover;
  background-position: center;
  position: relative;
}

.bodyDiv::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color:#e89246;
}

.contactSection {  
  position: relative;
  z-index: 3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.containerContactUs {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.section-header p {
  color: #FFF;
}

.row1  {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: space-between;
}

.contact-info {
  width: 40%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
  color: #e89246;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: rgb(255,255,255); 
  font-size: 1.4em;
  margin-bottom: 5px;
  font-weight: 600;
}

.contact-info-content p {
  color: #FFF;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  /* padding: 40px; */
  width: 60%;
  height: 320px;
  /* padding-bottom: 20px; */
  /* padding-top: 20px; */
  border-radius: 10px;
}
.contact-form iframe{
  Height: 320px !important;
}

/* .contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}
.input23{
    background-color: transparent;
    color: black;
    border-radius: 0px;
}
.textArea23{
    background-color: transparent;
    color: black;
    border-radius: 0px;
    min-height: auto;
    box-shadow: none;
}
.contact-form .input-box .input23,
.contact-form .input-box textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box .input23:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"]
{
  width: 100%;
  background: #e86322;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #e86322;
  transition: 0.5s;
}

.contact-form .input-box .input23[type="submit"]:hover
{
  background: #FFF;
  color: #00bcd4;
} */

@media (max-width: 991px) {
  /* section {
    padding-top: 50px;
    padding-bottom: 50px;
  } */
  
  .row1 {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}