/* .carousel .control-next.control-arrow:before{
    border-left: 30px solid #000000;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
}
.carousel.carousel-slider .control-arrow:hover {
 background: none;
}
.carousel .control-prev.control-arrow:before {
    border-right: 30px solid #000000;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
} */





/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
  }
  
  .flickity-enabled:focus {
    outline: none;
  }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  
  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
    left: 10px;
  }
  .flickity-prev-next-button.next {
    right: 10px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }
  
  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
  .FramerMotionCarouselBannner span {
    display: none !important;
  }
  .FramerMotionCarouselBannner {
    height: auto;
    overflow: hidden;     
  }
  .bannerImgHover:hover{
    cursor: pointer;
  }

  .arrowBanner{
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    color: rgb(255, 255, 255);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 20px;
    right: initial;
  }
  .CarouselBanner{
    padding: 0rem !important;
  }
  @media (max-width: 576px) {  
    .FramerMotionCarouselBannner img{
      height: 40vh !important;
    }
  }

  .centeredContent {
    position: absolute;
    top: 75%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  
  .bannerNewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bannerNewImageDiv {
    flex: 1;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 90vh;
  }
  .centeredContent h2{
    text-shadow: 0 2px 4px rgba(65,48,62,.3);
    font-size: 60px;
    font-weight: 700;
    /* line-height: 72px; */
    color: #fff;
  }
  .centeredContent button{
    box-shadow: 0 2px 4px 0 rgba(65,48,62,.3);
    background-color: #e89246;
    border-radius: 27px;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 15px 54px;
    text-transform: uppercase;
    text-align: center;
    color:#fff;
    border: 2px solid #e89246;
  }
  /* .bannerNewImageDiv::after{
    background: linear-gradient(180deg,rgba(65,48,62,0),#000);
  } */
  .react-multi-carousel-list{
    padding-bottom: 0rem !important;
  }
  .react-multiple-carousel__arrow{
    background-color: #fff;
    color: black;
  }
  .react-multiple-carousel__arrow:hover{
    background-color: #fff;
    color: black;
  }
  .react-multiple-carousel__arrow::before{
    color: black;
    font-weight: 700;
  }
  .react-multi-carousel-dot-list{
    bottom: 10px;
  }

  @media only screen and (max-width: 576px) and (min-width: 100px) {  
    .centeredContent button{
      padding: 12px 15px;
    } 
    .centeredContent h2{
      font-size: 7vw;
      color: #fff;
    }
    .bannerNewImageDiv{
      height: 75vh;
    }
    .BanCarMainDiv .react-multiple-carousel__arrow{    
      display: none;
    }
  }

  .bannerNewImageDiv::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(180deg,rgba(65,48,62,0),#000);     
    /* opacity: 0.5;  */
  }

  @media only screen and (max-width: 1200px) and (min-width: 576px) {  
    .centeredContent h2{
      font-size: 42px !important;
    }
  }

  