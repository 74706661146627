@import url('https://fonts.googleapis.com/css?family=Montserrat');

h1 {
  text-align: center;
  margin: 2rem 0;
  font-size: 2.5rem;
}
.faq {
    line-height: 1.5;
    font-family: system-ui, sans-serif;
    padding: 1rem 0;
    background-color: rgba(44,37,45,.04);
  }
  .faq-title {
    color: #414042;
    width: 1142px;
    margin-top: 1rem;
    margin-bottom: 1em;
    margin-left: auto !important;
    margin-right: auto !important;   
    /* margin  */
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 1.75rem;
      margin-left: auto !important;
    margin-right: auto !important; 
    }
  }
  @media (min-width: 768px) and (max-width: 979px) {
    .accordion,.faq-title {
      width: 750px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .accordion, .faq-title {
      width: 96% !important;
    }
  }
  @media (min-width: 980px) and (max-width: 1161px) {
    .accordion, .faq-title {
      width: 960px !important;
    }
  }
.accordion {
  width: 1142px;
  margin-top: 1rem;
    margin-bottom: 1em;
    margin-left: auto !important;
    margin-right: auto !important;  
}
.accordion-item {
  background-color: #fff;
  color: #111;
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
}
.accordion-item-header {
  display: flex;
  align-items: center;
  position: relative;
  color: #41303e;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.375rem 0.75rem;  
}
.accordion-item-header::after {
  content: "\002B";  
  font-size: 1rem;
  position: absolute;
  right: 1rem;  
}
.accordion-item.active .accordion-item-header::after {
  content: "\2212"; 
}
.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.accordion-item-body-content {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  padding: 0 10px 10px 34px;
}