@import url(https://fonts.googleapis.com/css?family=Lato:400,600,700);
.slider12 {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
  width: 94%;
  margin-top: -22px;
}
/* .propDivHoverSha :hover {
  cursor: pointer;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
} */
.reviewSectionPort {
  background-color: #fff;
  padding-bottom: 2rem;
}
.PortNewHead {
  text-align: justify;
  color: #222222;
  font-size: 35px;
  font-weight: 700; 
  padding-top: 2rem;
  padding-left: 106px;
  padding-right: 106px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.PortNewSecondHead {
  color: #41303e;
  font-size: 1.25rem;
  font-weight: 400;
}


@media (max-width: 768px) {
  .PortNewHead {
    font-size: 18px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 20px;
    text-align: left !important;
  }
  .PortNewSecondHead {
    font-size: 0.8rem;
  }
  .parent {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

/* .custom-dot-list-style button{
    border: none;
    background: #e86322;
    }
    .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    background: #e86322 !important;
    } */

.snip1527 {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  float: left;
  font-family: "Lato", Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  /* max-width: 310px; */
  /* min-width: 250px; */
  overflow: hidden;
  position: relative;
  text-align: left;
  border-radius: 1rem;
}
.imageSnip {
  width: 100%;
  height: 550px;
}
.snip1527 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1527:hover{
  box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 15px 10px rgba(0,0,0,.1);
  cursor: pointer;
}

.snip1527 img {
  width: 100%;
  vertical-align: top;
  position: relative;
  height: 100%;
  object-fit: cover;
}

.snip1527 figcaption {
  width: 100%;
  padding: 16px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(180deg, transparent 2.07%, #000);
}

.snip1527 figcaption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: #700877; */
  content: "";
  /* background: -moz-linear-gradient(90deg, #700877 0%, #ff2759 100%, #ff2759 100%); */
  /* background: -webkit-linear-gradient(90deg, #700877 0%, #ff2759 100%, #ff2759 100%); */
  /* background: linear-gradient(90deg, #700877 0%, #ff2759 100%, #ff2759 100%); */
  opacity: 0.8;
  z-index: -1;
}

.snip1527 h3 {
  margin: 0;
  padding: 0;
}

.snip1527 h3 {
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  font-size: 20px;
}

/* .snip1527:hover img,
.snip1527.hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
.detailDiv {
  padding: 1rem;
  height: 65vh !important;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}
.detailDiv::-webkit-scrollbar {
  display: none;
}
.mainDivProp {
  display: grid;
  /* padding: 1rem; */
  /* border: 2px solid black; */
  grid-template-columns: 65% 1fr;
  /* gap: 1rem; */
  align-items: center;
  max-width: 80%;
  margin: 5% auto;
  max-height: 85%;
  overflow: hidden;
  font: 500 100%/1.5 system-ui;
  background: #fff;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .mainDivProp {
    grid-template-columns: 1fr;
    overflow: scroll;
    max-height: max-content;
  }
  .detailDiv {
    height: 100% !important;
  }
}
.HeadingName {
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  text-align: justify !important;
}
.description {
  font-size: 12px;
  margin-top: 8px !important;
}
.bookButton {
  border: 1px solid #e86322;
  background-color: #e86322;
  border-radius: 2px;
  width: 95%;
  padding: 5px;
  color: white;
}
.innerDivModal {
  padding: 4rem;
}
.imgServiceModal {
  width: 100%;
  height: 450px;
  box-shadow: 2px 2px 42px 2px #ddd;
}
.social-links {
  display: flex;
  align-items: center;
}
.social-links .iconsModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #fff;
  /* border: 1px solid #000; */
  margin: 0 1rem;
  border-radius: 50%;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all 0.5s ease;
  background-color: #e86322;
}
.social-links .iconsModal:hover {
  background: #000;
  border-color: transparent;
  color: #fff;
}
.ShareDesign {
  font-size: 16px;
}
.FacebookIcon {
  margin-left: 0px !important;
}
@media (max-width: 1000px) {
  .main {
    display: block;
  }
  p {
    position: relative;
    /* background-color: #ffffff85 */
  }
  .imgServiceModal {
    width: 100%;
    background-position: center;
    object-fit: cover;
  }
  h1 {
    font-size: 35px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.FramerMotionCarouselPropModal span {
  display: none !important;
}
.FramerMotionCarouselPropModal {
  height: 65vh;  
  overflow: hidden;
  background-color: rgb(45, 45, 45); 
}

.custom-carousel-Propmodal {
  height: 100%;
}

.carousel-image {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}
.getPropBtn {
  border-radius: 10px;
  font-size: 17px !important;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 24px;
  padding: 10px 20px; 
  text-align: center;
  background-color: #e89246;
  border: none;
  border-color: #e89246;
  margin-top: 35px;
  color: #fff;
}
.getPropBtn:hover{
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); 
}

@media (max-width: 991px) {
  .PortNewHead{
    display: grid;  
  }
}
.CarouselPropModal{
  height: 100%;
  padding: 0rem !important; 
}
.CarouselPropModal ul{
  height: 100%;
}

/* New Cards Project */
/* .cardProp{
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
  border-radius: 16px;
}
.imgDivPropCard{
  height: 200px;
}
.imgDivPropCard img{
  height: 100%;
  width: -webkit-fill-available !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.textCardProp{
  padding: 0.75rem;
  justify-content: center;
}
.textCardProp1 h4{
  line-height: 24px;
  font-size: 20px;
  color: #41303e;
 text-align: center;
 margin-top: 0.50rem;
}
.textCardProp1 p{
  line-height: 24px;
  font-size: 16px;
  color: #7a6e77;
 text-align: center;
 margin-top: 0.50rem;
}
.textCardPropIcon{
  display: flex;
  justify-content: center;
}
.textCardPropIcon span{
  width: 28px;
  text-align: center;
  border-radius: 25px;
}
.textCardPropIcon i{
 font-size: 24px !important;
}
.cardProp:hover{
  box-shadow: 0 15px 20px rgba(0,0,0,.05), 0 0 15px 10px rgba(0,0,0,.1);
    transform: translateY(-2px);
    cursor: pointer;
}
.cardProp:hover .textCardIconSpan{
  background-color: #e89246;
  color: #fff !important;
} */
.divBtnGetResponsive{
  display: none;
}
.PortfolioCarouselClass{
  height: 630px;
}
.PortfolioCarouselClass .react-multi-carousel-dot-list{
  bottom: -2px !important;     
} 
@media (max-width: 576px) {  
  .FramerMotionCarouselPropModal {
    height: 55vh; 
    overflow: hidden;
    background-color: rgb(45, 45, 45);
}
  .reviewSectionPort{
    background-color: #f5f5f5 !important;
  }
  .imageSnip{
    height: 350px !important;
  }
  /* .PortfolioCarouselClass li{
    width: 260px !important; 
  } */
  .parent{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .PortTopHeading{
    font-size: larger;
  }
  .divBtnPort{
    display: none;
  }
  .divBtnGetResponsive{
    display: flex;
    justify-content: center;
  }
  .getQuotebtnRespon {
    border-radius: 10px;
    font-size: 17px !important;
    font-weight: 500;
    letter-spacing: 0.75px;
    line-height: 24px;
    padding: 10px 20px;
    text-align: center;
    background-color: #e89246;
    border: none;
    border-color: #e89246;
    margin-top: 20px;  
    color: #fff;
    width: 80%;
}
.PortfolioCarouselClass .react-multi-carousel-dot-list{
  /* display: none;    */
}

.PortfolioCarouselClass  .react-multiple-carousel__arrow{
  display: none !important;
}


.PortfolioCarouselClass{
  height: 395px !important;
}
.propCardNew{
  padding: 0rem !important;
}
.slider12{
  margin-left: 10px !important;
  margin-right: 10px !important;
}
}


@media only screen and (max-width: 991px) and (min-width: 576px) {  
  .getPropBtn{
    margin-top: 0px !important;  
  }
  .imageSnip{
    height: 400px !important;
  }   
  .PortfolioCarouselClass{
    height: 478px !important;  
  }
  .propCardNew{
    padding: 0rem !important;
  }
}
